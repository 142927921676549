import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useContext } from 'react';
import { View, Alert, Pressable, Modal, Linking } from 'react-native';
import { BodyText } from '../../components/Typography';
import { tailwind } from '../../styles/tailwind';
import webLayoutConstraints from '../../styles/webLayoutConstraints';
import { Routes } from '../types';
import { HeaderContext } from './header/Header';
import HeaderLeft from './header/HeaderLeft';
import HeaderRight from './header/HeaderRight';

interface MenuRoute {
  route: Routes | string;
  displayName: string;
  external?: boolean;
}

const menuRoutes: MenuRoute[] = [
  // {
  //   route: RootRoutes.MYCARS,
  //   displayName: 'Mine biler',
  // },
  // {
  //   route: RootRoutes.WASHHISTORY,
  //   displayName: 'Vaskehistorikk',
  // },
  // {
  //   route: RootRoutes.ROOT,
  //   displayName: 'Dashboard',
  // },
  {
    route: 'https://www.swash.no/brukervilkar',
    displayName: 'Brukervilkår',
    external: true,
  },
  {
    route: 'https://www.swash.no/personvern',
    displayName: 'Personvern',
    external: true,
  },
];

const MenuModal = () => {
  const { modalVisible, setModalVisible } = useContext(HeaderContext);
  const navigation = useNavigation<NativeStackNavigationProp<any, Routes>>();

  const onNavigate = (route: MenuRoute) => {
    if (route.external) {
      Linking.openURL(route.route);
    } else {
      setModalVisible((prev) => !prev);
      navigation.navigate(route.route);
    }
  };

  return (
    <Modal
      animationType="fade"
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.');
        setModalVisible((prev) => !prev);
      }}
      presentationStyle="pageSheet"
    >
      <View style={tailwind('flex-1 bg-swash-saphire')}>
        <View
          style={{
            ...tailwind('flex-row my-4 justify-between bg-swash-saphire w-full'),
            ...webLayoutConstraints,
          }}
        >
          <HeaderLeft />
          <HeaderRight />
        </View>
        <View style={tailwind('flex-1 justify-center items-center')}>
          {menuRoutes.map((route) => (
            <Pressable
              key={route.route}
              onPress={() => onNavigate(route)}
              style={tailwind('mb-12')}
            >
              <BodyText style={tailwind('underline')}>{route.displayName}</BodyText>
            </Pressable>
          ))}
        </View>
      </View>
    </Modal>
  );
};

export default MenuModal;
