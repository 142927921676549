import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, View } from 'react-native';
import WashRoutes from '../../domain/wash/WashRoutes';
import RootRoutes from '../navigation/RootRoutes';
import { tailwind } from '../styles/tailwind';
import Layout from './Layout';
import { H2, BodyText } from './Typography';

const ScreenError = () => {
  const navigation = useNavigation<any>();

  return (
    <Layout center>
      <View style={tailwind('justify-center items-center')}>
        <Pressable onPress={() => navigation.replace(RootRoutes.LOGIN)}>
          <BodyText style={tailwind('underline')}>Go Back</BodyText>
        </Pressable>
        <H2>En feil oppstod</H2>
        <Pressable
          onPress={() => {
            navigation.replace(WashRoutes.WASHLANDING);
          }}
        >
          <BodyText style={tailwind('underline')}>Gå tilbake til hovedsiden</BodyText>
        </Pressable>
      </View>
    </Layout>
  );
};

export default ScreenError;
