import React from 'react';
import { View } from 'react-native';

import Card from '../../../common/components/Card';
import Chip from '../../../common/components/Chip';
import { BodyText, H2 } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';
import { Car } from '../types';

interface Props {
  car: Car;
}

const CarCard = ({ car }: Props) => {
  const { tekniskKjoretoy } = car;
  const carDetails = `${tekniskKjoretoy.karosseri.farge} ${tekniskKjoretoy.merke} ${tekniskKjoretoy.handelsbetegnelse}`;

  return (
    <Card backgroundColor="transparent" border="border-dashed" style={tailwind('mb-4')}>
      <Card.Content>
        <View>
          <H2 style={tailwind('uppercase m-0')}>{car.kjennemerke}</H2>
          <BodyText style={tailwind('capitalize')}>{carDetails}</BodyText>
        </View>
        <Chip>
          <BodyText>Privat</BodyText>
        </Chip>
      </Card.Content>
    </Card>
  );
};

export default CarCard;
