import React from 'react';
import { View } from 'react-native';
import { tailwind } from '../styles/tailwind';

interface RatingsProps {
  rating: number;
  maxRating?: number;
}

interface RatingProps {
  active: boolean;
  firstElement?: boolean;
}

const RatingIcon = ({ active, firstElement }: RatingProps) => {
  const activeClass = active ? '' : 'opacity-50';
  const firstElementClass = firstElement ? 0 : 5;

  return (
    <View
      style={{
        ...tailwind(`h-5 rounded-full bg-swash-soap ${activeClass}`),
        width: 5,
        marginLeft: firstElementClass,
      }}
    />
  );
};

const RatingIcons = ({ rating, maxRating = 3 }: RatingsProps) => {
  return (
    <View style={tailwind('flex flex-row w-6')}>
      {Array(maxRating)
        .fill(null)
        .map((r, i) => (
          // TODO: Need to use index as key. Cannot use a random key -> Issues with react.
          // eslint-disable-next-line react/no-array-index-key
          <RatingIcon firstElement={i === 0} key={i} active={i < rating} />
        ))}
    </View>
  );
};

export default RatingIcons;
