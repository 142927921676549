import React from 'react';
import { View } from 'react-native';
import { tailwind } from '../styles/tailwind';
import { swashColor } from '../styles/types';

interface Props {
  children: React.ReactNode;
  backgroundColor?: swashColor;
}

const Chip = ({ children, backgroundColor = 'swash-water' }: Props) => {
  const backgroundColorStyle = `bg-${backgroundColor}`;
  return <View style={tailwind(`px-4 rounded-full ${backgroundColorStyle}`)}>{children}</View>;
};

export default Chip;
