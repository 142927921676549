import React, { useContext } from 'react';
import { Pressable, Image } from 'react-native';
import { tailwind } from '../../../styles/tailwind';
import { HeaderContext } from './Header';

const cross = require('../../../assets/icons/cross.svg');
const hamburger = require('../../../assets/icons/hamburger.svg');

const HeaderRight = () => {
  const { modalVisible, setModalVisible } = useContext(HeaderContext);

  return (
    <Pressable
      style={tailwind('mr-4 items-center justify-center')}
      onPress={() => setModalVisible((prev) => !prev)}
    >
      <Image source={modalVisible ? cross : hamburger} style={tailwind('h-6 w-6')} />
    </Pressable>
  );
};

export default HeaderRight;
