import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { WasherTypesEnum } from '../../../common/graphql/generated/apollo';
import { useAppState } from '../../../common/state';
import { possibleQueryParams, useQueryPopulation } from '../../../common/state/useQueryPopulation';
import { getProgramPrice, priceToString } from '../../../common/utils/price';
import WashRoutes from '../WashRoutes';
import { tailwind } from '../../../common/styles/tailwind';
import Layout from '../../../common/components/Layout';
import ScreenLoader from '../../../common/components/ScreenLoader';
import WashPaymentFields from '../../payment/components/WashPaymentFields';
import WashPaymentSummary from '../../payment/components/WashPaymentSummary';
import { Swash } from '../../../common/graphql/types.d';
import { useToast } from '../../../common/hooks/useToast';
import ScreenError from '../../../common/components/ScreenError';

const WashSummary = ({
  navigation,
  route,
}: NativeStackScreenProps<any, WashRoutes.WASHSUMMARY>) => {
  const requiredParams: possibleQueryParams[] = ['id', 'washer', 'program', 'price', 'washGrantId'];
  const { isLoadingQueryParams } = useQueryPopulation(requiredParams, route);

  const washer = useAppState((state) => state.washer);
  const location = useAppState((state) => state.location);
  const washerProgram = useAppState((state) => state.washerProgram);
  const programPrice = useAppState((state) => state.programPrice);
  const washGrantId = useAppState((state) => state.washGrantId);
  const toast = useToast();

  const washerIsSelfservice = useCallback(
    () => washer?.washer_type === WasherTypesEnum.SELF_SERVICE,
    [washer]
  );

  // Set toast-notification message based on error-code
  useEffect(() => {
    const error = route.params?.error;

    if (error) {
      switch (error as Swash.AdyenVippsResponseCode) {
        case Swash.AdyenVippsResponseCode.CANCELLED:
          // BUG: For some weird reason the error message is not being displayed if not timed out. Probably trying to display it too early.
          setTimeout(() => {
            toast.warning('Cancelled', 'Du avbrøt Vipps-betaling', {
              autoHide: true,
            });
          }, 500);
          break;
        case Swash.AdyenVippsResponseCode.REFUSED:
          setTimeout(() => {
            toast.danger('Refused', 'Vipps-forespørselen ble avslått', {
              autoHide: true,
            });
          }, 500);
          break;
        case Swash.AdyenVippsResponseCode.ERROR:
          setTimeout(() => {
            toast.danger('Error', 'Vipps-betalingen feilet', {
              autoHide: true,
            });
          }, 500);
          break;
        default:
          setTimeout(() => {
            toast.danger('Error', 'Noe gikk galt med Vipps-betalingen', {
              autoHide: true,
            });
          }, 500);
          break;
      }
    }
    // Including 'toast' in the dependency-array causes weird re-renders of the toast-message. Works great without it.
  }, [route.params?.error]);

  // Validate washgrantId and redirect on valid
  useEffect(() => {
    const price = washerIsSelfservice() ? programPrice : washerProgram?.price_details?.price;
    if (washGrantId) {
      navigation.replace(WashRoutes.WASHCONFIRMATION, {
        id: location?.id,
        washer: washer?.id,
        program: washerProgram?.id,
        price,
        washGrantId,
      });
    }
  }, [washGrantId, navigation, washerProgram, location, washer, programPrice, washerIsSelfservice]);

  if (isLoadingQueryParams) return <ScreenLoader />;
  if (!washer || !location || !washerProgram || !programPrice) return <ScreenError />;

  return location && washer && washerProgram ? (
    <Layout>
      <View style={tailwind('mt-8 h-full')}>
        <WashPaymentSummary
          locationName={location.name}
          price={priceToString(
            washerIsSelfservice() ? programPrice : getProgramPrice(washerProgram)
          )}
          programName={washerProgram.name}
        />
        {/* <BodySmall style={tailwind('mt-4 mx-1')}>
          Hos oss er det trygt å betale for vi er veldig snille. Porten åpner når du har betalt
        </BodySmall> */}

        <WashPaymentFields
          onSuccessfullPayment={() => {}}
          location={location}
          washer={washer}
          washerProgram={washerProgram}
        />
      </View>
    </Layout>
  ) : null;
};

export default WashSummary;
