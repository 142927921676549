import * as React from 'react';
import { View } from 'react-native';
import { G, Path, Svg } from 'react-native-svg';

const SwashLogo = () => {
  const scaledWidth = 84;
  const scaledHeight = 30;
  const originalWidth = 1080;
  const originalHeight = 382;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <View style={{ aspectRatio }}>
      <Svg
        id="Layer_1"
        viewBox={`0 0 ${originalWidth} ${originalHeight}`}
        fill="#ffffff"
        width={scaledWidth}
        height={scaledHeight}
      >
        <G>
          <G>
            <G>
              <Path
                d="M90.6,237.8c-1,12.1-4.4,23.9-8.3,35.5c-1.5,4.5-2.2,6.7-3.7,11.3c-2.6,7.8-1.1,12.5,5.2,13.6
				c6.4,1,12.6-2.9,15.5-11.9c5.6-17.1,2.5-42-9.2-71.5c-3.4-8.8-5.2-13.2-8.5-22C68.9,159.2,51.8,124,68,79.6
				c17-46.8,58.7-70.1,109.9-61.8s74,41.8,56.8,100.1c-2.1,7.2-3.2,10.8-5.3,18c-28.9-3.7-43.3-5.7-72-10.1
				c1-12.1,4.4-23.8,8.2-35.6c0.9-2.9,1.4-4.4,2.4-7.3c2.6-7.8,1.2-12.5-4.6-13.4c-5.8-0.9-11.7,3.1-14.7,12
				c-5.3,15.9-1.7,37.5,10.8,69.1c3.5,8.7,5.2,13.1,8.8,21.7c13.9,34.2,30.7,69.5,17.6,113.7c-13.9,47.2-58.6,73.2-116.4,63.9
				c-57.8-9.3-81.9-46.2-61-103.9c3.1-8.7,4.7-12.9,7.8-21.7C45.9,230.1,60.8,232.8,90.6,237.8z"
              />
              <Path
                d="M414.7,48.8c-8.8,60.2-13.1,90.2-21.3,150.5c1.7,0.1,2.5,0.1,4.2,0.2c16.9-59.7,25.4-89.6,42-149.3
				c26.5,1.4,39.7,1.9,66.2,2.6c-0.3,1.6-0.4,2.4-0.8,3.9c-35.1,128.6-53.4,193.2-91,321.7c-35.5-1.3-53.2-2.2-88.5-4.5
				c7.1-51.7,10.7-77.6,18.1-129.3c-1.7-0.1-2.5-0.2-4.2-0.3c-17.6,51-26.6,76.5-44.7,127.4c-38.7-3-58-4.8-96.4-9.2
				c26-129.5,39.5-194.3,67.6-323.5c0.4-1.6,0.7-2.3,1.2-3.9c28.9,3.6,43.4,5.1,72.5,7.8c-12.6,60-18.8,90-31.1,150.1
				c1.7,0.2,2.5,0.2,4.2,0.3c20.9-59.2,31.2-88.9,51.6-148.3C384.4,46.8,394.5,47.5,414.7,48.8z"
              />
              <Path
                d="M660,52.4c-5.9,128.6-8.2,192.9-11.1,321.3c-0.2,1.5-0.3,2.3-0.5,3.9c-36.2,1.6-54.2,2.1-90.2,2.6
				c2.7-30.7,4.1-46,7-76.6c-11.3,0.1-17,0.2-28.3,0.2c-7.3,30.7-11.1,46-18.5,76.7c-31.8,0-47.7-0.2-79.3-1.1
				c0.3-1.6,0.4-2.4,0.8-3.9c39.2-128.6,58-193.2,94.1-321.9C584.2,54.2,609.4,54,660,52.4z M571,240.5
				c4.1-42.3,6.1-63.5,10.3-105.8c-1.6,0-2.5,0-4.1,0.1c-10,42.3-15.1,63.5-25.3,105.9C559.6,240.7,563.4,240.7,571,240.5z"
              />
              <Path
                d="M749,266.3c1.9,11.6,1.2,23.3,0.1,35.1c-0.4,4.6-0.7,6.8-1.1,11.4c-0.7,7.9,1.9,11.9,8.6,11.4
				s11.9-5.7,12.8-14.7c1.5-17.2-7.4-39.8-26.3-64.6c-5.6-7.4-8.3-11.2-13.9-18.6c-20.7-28.3-46.3-57.2-40.6-102.4
				c5.9-47.6,42.5-78.9,96-83.1c53.7-4.2,84.9,21.9,81.5,80.2c-0.4,7.2-0.6,10.8-1.1,18c-30.1,3.3-45.2,4.8-75.2,7.3
				c-1.9-11.6-1.3-23.3-0.2-35.1c0.3-2.9,0.4-4.4,0.7-7.4c0.7-7.8-1.8-11.9-7.9-11.4c-6.1,0.4-11.1,5.6-11.9,14.6
				c-1.6,16.1,7.1,35.3,27.5,62c5.6,7.3,8.4,10.9,14,18.3c22.2,28.6,47.7,57.6,45.1,101.9c-2.8,47.3-41.9,81.9-102.5,86.6
				c-60.6,4.7-93.6-24.2-86.3-82.7c1.1-8.8,1.7-13.2,2.8-22.1C702.1,269.5,717.7,268.7,749,266.3z"
              />
              <Path
                d="M971,24.5c-0.9,51.7-1.5,77.6-2.6,129.2c7.8-1.1,11.7-1.7,19.4-2.9c0.7-51.6,1.1-77.5,1.7-129.1
				c33.3-5.1,50-7.9,83.3-14.1c2.7,128.4,3.4,192.5,6.1,320.9c-37.5,6.9-56.2,10.1-93.6,15.8c0.6-52.5,0.9-78.8,1.7-131.3
				c-7.9,1.2-11.9,1.8-19.9,3c-1.1,52.6-1.7,78.9-2.7,131.5c-37.5,5.5-56.2,7.9-93.6,12.3C877.6,230,881.3,165.1,888,35.4
				C921.1,31.6,937.8,29.4,971,24.5z"
              />
            </G>
          </G>
        </G>
      </Svg>
    </View>
  );
};

export default SwashLogo;
