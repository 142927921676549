import React from 'react';
import { View, ViewStyle } from 'react-native';
import { tailwind } from '../styles/tailwind';
import { swashBorder, swashColor } from '../styles/types';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  backgroundColor?: swashColor;
  border?: swashBorder;
  style?: ViewStyle;
}

interface InfoProps {
  children: React.ReactNode;
}

const Card = ({ children, backgroundColor = 'swash-water', border, style }: Props) => {
  const backgroundColorStyle = `bg-${backgroundColor}`;
  const borderStyle = border ? `${border} border-swash-snow border` : '';

  return (
    <View
      style={{
        ...tailwind(
          `flex-row items-center rounded-xl px-6 py-3 w-full ${backgroundColorStyle} ${borderStyle}`
        ),
        ...style,
      }}
    >
      {children}
    </View>
  );
};

const Icon = ({ children }: { children: React.ReactNode }) => {
  return <View style={tailwind('mr-4 w-8 h-8 justify-center')}>{children}</View>;
};

const Content = ({ children }: InfoProps) => {
  return (
    <View style={tailwind('flex-row flex-1 flex-wrap justify-between items-center max-w-full')}>
      {children}
    </View>
  );
};

Card.Content = Content;
Card.Icon = Icon;

export default Card;
