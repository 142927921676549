import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import * as Linking from 'expo-linking';
import { isMobile } from 'react-device-detect';
import { useNavigation } from '@react-navigation/native';
import { tailwind } from '../../../common/styles/tailwind';
import { useAppState } from '../../../common/state';
import { validatePhoneNumber } from '../../../common/utils/phoneValidation';
import { Swash } from '../../../common/graphql/types';
import { CreateOrderInput, useCreateOrderMutation } from '../../../common/graphql/generated/apollo';
import Input from '../../../common/components/Input';
import KeyboardAvoidingViewSwash from '../../../common/components/KeyboardAvoidingViewSwash';
import WashRoutes from '../../wash/WashRoutes';
import { useToast } from '../../../common/hooks/useToast';
import PromoCodeInput from './PromoCodeInput';

interface Props {
  onSuccessfullPayment: () => void;
  washer: Swash.Washer;
  washerProgram: Swash.WasherProgram;
  location: Swash.Location;
}

const WashPaymentFields = ({ washer, washerProgram, location }: Props) => {
  const [promoCode, setPromoCode] = useState('');
  const navigation = useNavigation<any>();

  const [createOrder] = useCreateOrderMutation();
  const phoneNumber = useAppState((state) => state.phoneNumber);
  const programPrice = useAppState((state) => state.programPrice);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const toast = useToast();

  const onPay = async () => {
    const formattedPhoneNumber = validatePhoneNumber(phoneNumber);
    const returnPath = `/${location.id}/summary?washer=${washer.id}&program=${washerProgram.id}&price=${programPrice}`;

    setLoadingPayment(true);

    const input: CreateOrderInput = {
      phoneNumber: formattedPhoneNumber.toString(),
      paymentChannel: Platform.OS,
      isMobile: Platform.OS === 'web' ? isMobile : true,
      programId: washerProgram.id,
      returnUrl: Linking.createURL(returnPath),
      promoCode,
    };

    if (programPrice) {
      input.price = programPrice;
    }

    try {
      const { data, errors } = await createOrder({
        variables: {
          input,
        },
      });

      if (errors) {
        throw new Error(errors[0].message);
      }
      const response = data?.createOrder;
      if (response?.washGrantId) {
        navigation.navigate(WashRoutes.WASHCONFIRMATION, {
          id: location?.id,
          washer: washer?.id,
          program: washerProgram?.id,
          washGrantId: response.washGrantId,
        });
        return true;
      }
      if (!response?.actionUrl) {
        throw new Error('No actionUrl returned');
      }
      Linking.openURL(response.actionUrl);
    } catch (error: any) {
      if (error.message?.includes('Promo code')) {
        toast.danger('Promokoden er ugyldig for denne lokasjonen');
      } else {
        toast.danger('Kunne ikke starte betalingen, vennligst prøv på nytt');
      }

      return false;
    } finally {
      setLoadingPayment(false);
    }
    return true;
  };

  return (
    <View style={tailwind('flex-1 justify-between h-full mt-4 mb-8')}>
      <KeyboardAvoidingViewSwash style={tailwind('h-full justify-between')}>
        <PromoCodeInput promoCode={promoCode} setPromoCode={setPromoCode} />
        <Input seperateFields>
          <Input.PhoneField onSubmitEditing={onPay} />
          <Input.PhoneButton title="Betal" onPress={onPay} loading={loadingPayment} />
        </Input>
      </KeyboardAvoidingViewSwash>
    </View>
  );
};

export default WashPaymentFields;
