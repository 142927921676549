import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity, Image } from 'react-native';

import Card from '../../../common/components/Card';
import { BodyText } from '../../../common/components/Typography';
import { Swash } from '../../../common/graphql/types';
import { tailwind } from '../../../common/styles/tailwind';
import WashRoutes from '../WashRoutes';

const carWashIcon = require('../../../common/assets/icons/car_wash.svg');

interface Props {
  locations: Swash.Location[];
  navigation: any;
}

const LocationSelector = ({ locations }: Props) => {
  const navigation = useNavigation<any>();

  return (
    <>
      {locations.map((loc) => {
        return (
          <TouchableOpacity
            style={tailwind('mb-4')}
            onPress={() =>
              navigation.navigate(WashRoutes.WASHLOCATION, {
                id: loc.id,
              })
            }
            key={loc.id}
          >
            <Card>
              <Card.Icon>
                <Image source={carWashIcon} style={tailwind('h-6 w-6')} />
              </Card.Icon>
              <Card.Content>
                <BodyText>{loc.name}</BodyText>
              </Card.Content>
            </Card>
          </TouchableOpacity>
        );
      })}
    </>
  );
};

export default LocationSelector;
