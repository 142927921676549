import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { View } from 'react-native';
import { tailwind } from '../../common/styles/tailwind';
import { fullSlideInterpolation } from '../../common/navigation/utils/fullSlideInterpolation';
import AssetBackgroundImage from '../../common/components/AssetBackgroundImage';
import WashPaymentConfirmed from './screens/WashConfirmation';
import WashLocation from './screens/WashLocation';
import WashProgram from './screens/WashProgram';
import WashSummary from './screens/WashSummary';
import WashType from './screens/WashType';
import WashRoutes from './WashRoutes';
import WashWizardHeader from './wizard/components/WashWizardHeader';
import WashLanding from './screens/WashLanding';

const WashStack = createStackNavigator();

const WashNavigator = () => {
  return (
    <View style={tailwind('bg-transparent flex-1')}>
      <AssetBackgroundImage />
      <WashStack.Navigator
        initialRouteName={WashRoutes.WASHLOCATION}
        screenOptions={{
          title: 'Swash',
          header: ({ progress }) => <WashWizardHeader progress={progress} />,
          headerMode: 'float',
          headerTransparent: true,
          cardStyleInterpolator: ({ current, next, inverted, layouts: { screen } }) =>
            fullSlideInterpolation({ current, next, inverted, layouts: { screen } }),
        }}
      >
        <WashStack.Screen
          name={WashRoutes.WASHLANDING}
          options={{
            headerShown: false,
          }}
          component={WashLanding}
        />
        <WashStack.Screen
          name={WashRoutes.WASHLOCATION}
          options={{
            headerShown: false,
          }}
          component={WashLocation}
        />
        <WashStack.Screen
          name={WashRoutes.WASHPROGRAM}
          options={{
            headerShown: true,
          }}
          component={WashProgram}
        />
        <WashStack.Screen
          name={WashRoutes.WASHTYPE}
          component={WashType}
          options={{
            headerShown: true,
          }}
        />
        <WashStack.Screen
          name={WashRoutes.WASHSUMMARY}
          component={WashSummary}
          options={{ headerShown: true }}
        />
        <WashStack.Screen
          name={WashRoutes.WASHCONFIRMATION}
          component={WashPaymentConfirmed}
          options={{ headerShown: false }}
        />
      </WashStack.Navigator>
    </View>
  );
};

export default WashNavigator;
