import React from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Swash } from '../../../common/graphql/types';
import { useAppState } from '../../../common/state';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import WashTypeCard from '../components/WashTypeCard';
import WashRoutes from '../WashRoutes';
import { tailwind } from '../../../common/styles/tailwind';
import Layout from '../../../common/components/Layout';
import ScreenLoader from '../../../common/components/ScreenLoader';
import ScreenError from '../../../common/components/ScreenError';
import ScrollableContent from '../../../common/components/ScrollableContent';

const WashType = ({ navigation, route }: NativeStackScreenProps<any, WashRoutes.WASHTYPE>) => {
  const { isLoadingQueryParams } = useQueryPopulation(['id'], route);
  const location = useAppState((state) => state.location);
  const setWasher = useAppState((state) => state.setWasher);
  const setWasherProgram = useAppState((state) => state.setWasherProgram);
  const setProgramPrice = useAppState((state) => state.setProgramPrice);

  const onSelectWasher = (washer: Swash.Washer) => {
    setWasher(washer);
    // Reset the program-choice to not cause wizardHeader-bug when navigating weirdly
    setWasherProgram(null);
    setProgramPrice(null);

    navigation.push(WashRoutes.WASHPROGRAM, {
      id: location?.id,
      washer: washer.id,
    });
  };

  if (isLoadingQueryParams) return <ScreenLoader />;
  if (!location) return <ScreenError />;

  return (
    <Layout withPadding={false}>
      <ScrollableContent style={tailwind('mt-8')}>
        {location?.washers.map((washer) => (
          <View key={washer.id} style={tailwind('mb-4')}>
            <WashTypeCard washer={washer} onPress={() => onSelectWasher(washer)} />
          </View>
        ))}
      </ScrollableContent>
    </Layout>
  );
};

export default WashType;
