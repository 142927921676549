import { Car } from './types';

const mockResponse: Car = {
  kjennemerke: 'EB36188',
  understellsnummer: 'VR3UHZKXZKT118478',
  kuid: '3092186711',
  personligKjennemerke: null,
  registrering: {
    registreringsstatus: 'REGISTRERT',
    registreringsstatusDato: '2020-03-18',
    forstegangsregistrering: '2020-03-18',
    forstegangsregistreringNorge: '2020-03-18',
    forstegangsregistreringEier: '2020-03-18',
    kjennemerkefarge: 'Sorte tegn på hvit bunn',
    avregistrertHosBilforhandler: false,
  },
  periodiskKjoretoykontroll: {
    sistKontrollert: null,
    nesteKontroll: '2024-03-18',
  },
  tekniskKjoretoy: {
    handelsbetegnelse: '208',
    typebetegnelse: 'U',
    merke: 'PEUGEOT',
    kjoretoyAvgiftskode: '101',
    tekniskKode: 'M1',
    miljoEuroklasse: null,
    sitteplasser: 5,
    staplasser: null,
    lengde: 4055,
    bredde: 1745,
    hoyde: 1430,
    lastegenskaper: {
      egenvekt: 1500,
      tillattTotalvekt: 1910,
      nyttelast: 335,
      tillattTilhengervektMedBrems: 0,
      tillattTilhengervektUtenBrems: 0,
      tillattVertikalKoplingslast: 0,
      tillattVogntogvekt: 0,
      tillattTaklast: null,
    },
    maksimumHastighet: 150,
    hybridElektriskKjoretoy: null,
    girkasse: 'A',
    hybridkategori: 'INGEN',
    motorer: [
      {
        slagvolum: null,
        drivstoff: [
          {
            drivstofftype: 'Elektrisk',
            drivstoffkode: '5',
            effekt: 100,
            maksEffektPerTime: 57,
          },
        ],
        partikkelfiltermotor: null,
      },
    ],
    karosseri: {
      farge: 'Rød',
      fargekode: '02',
    },
    forbrukOgUtslipp: [
      {
        drivstoffkode: '5',
        malemetode: 'WLTP',
        forbrukBlandetKjoring: null,
        co2Utslipp: null,
        noxUtslipp: {
          prKWh: null,
          prKm: null,
        },
        rekkeviddeKm: null,
        elEnergiForbruk: null,
        partikkelfilterFabrikkmontert: null,
      },
    ],
    aksler: {
      drivaksler: 1,
      dekkOgFelger: [
        {
          akselId: 1,
          belastningskode: '84',
          hastighetskode: 'P',
          innpress: '32',
          dekkdimensjon: '195/55R16 ',
          felgdimensjon: '6.50J16',
        },
        {
          akselId: 2,
          belastningskode: '84',
          hastighetskode: 'P',
          innpress: '32',
          dekkdimensjon: '195/55R16 ',
          felgdimensjon: '6.50J16',
        },
        {
          akselId: 1,
          belastningskode: '84',
          hastighetskode: 'P',
          innpress: '32',
          dekkdimensjon: '205/45R17 ',
          felgdimensjon: '7.00J17',
        },
        {
          akselId: 2,
          belastningskode: '84',
          hastighetskode: 'P',
          innpress: '32',
          dekkdimensjon: '205/45R17 ',
          felgdimensjon: '7.00J17',
        },
      ],
      aksler: [
        {
          akselId: 1,
          tillattLast: 965,
          avstandtilNesteAksel: 2540,
          drivaksel: true,
          luftfjaering: null,
        },
        {
          akselId: 2,
          tillattLast: 960,
          avstandtilNesteAksel: null,
          drivaksel: false,
          luftfjaering: null,
        },
      ],
    },
    unntak: [],
    oppbygd: false,
  },
  bruktimport: null,
  vektarsavgiftOppgittGrunnlag: null,
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const getCar = async (regNr: string): Promise<Car> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockResponse);
    }, 1000);
  });
};
