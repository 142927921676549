import React from 'react';
import { View } from 'react-native';

import Input from '../../../common/components/Input';
import { BodyText } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';

interface Props {
  loading: boolean;
  regNr: string;
  onChangeText: (text: string) => void;
  addCar: () => void;
}

const CarSearch = ({ loading, regNr, onChangeText, addCar }: Props) => {
  return (
    <View style={tailwind('flex-1')}>
      <BodyText>Skriv inn registreringsnummeret på bilen din for å komme i gang</BodyText>
      <View style={tailwind('justify-end flex-1')}>
        <Input>
          <Input.Field
            value={regNr}
            placeholder="Registreringsnummer"
            onChangeText={onChangeText}
            onSubmitEditing={addCar}
          />
          <Input.Button title="Legg til bil" onPress={addCar} loading={loading} />
        </Input>
      </View>
    </View>
  );
};

export default CarSearch;
