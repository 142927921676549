const getEnv = () => ({
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  HASURA_GRAPHQL_URL: process.env.HASURA_GRAPHQL_URL,
  ENVIRONMENT: process.env.ENVIRONMENT,
});

export const appConfig = {
  env: getEnv(),
  sentry: {
    dsnApp: 'https://65c4c107c3734373b208db077e762da3@o242872.ingest.sentry.io/6169086',
    dsnWeb: 'https://21057b51b16f4c91858d2394b02d469f@o242872.ingest.sentry.io/6169441',
  },
};

// TODO: use this function on build-time
export const validateEnv = () => {
  const { env } = appConfig;
  const keys = Object.keys(env);
  // @ts-ignore
  const missing = keys.filter((key) => !env[key]);
  if (missing.length > 0) {
    throw new Error(`Missing environment variables: ${missing.join(', ')}`);
  }
};
