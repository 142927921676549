import React from 'react';
import { View } from 'react-native';
import { tailwind } from '../styles/tailwind';
import { BodySmall } from './Typography';

interface StepProps {
  index: number;
  children: React.ReactNode;
}

const Step = ({ children, index }: StepProps) => {
  return (
    <View style={tailwind('flex-row items-center my-1')}>
      <View
        style={tailwind('h-8 w-8 mr-4 justify-center items-center bg-swash-water rounded-full')}
      >
        <BodySmall>{index}</BodySmall>
      </View>
      <View style={tailwind('flex-1 m-0 p-0')}>{children}</View>
    </View>
  );
};

interface ListStepsProps {
  children: React.ReactNode[];
}

const ListSteps = ({ children }: ListStepsProps) => {
  return (
    <View>
      {children.map((child, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <View key={index}>
            <Step index={index + 1}>{child}</Step>
            {index !== children.length - 1 && (
              <View style={tailwind('ml-3.5 bg-swash-water w-1 h-4 rounded-full opacity-25')} />
            )}
          </View>
        );
      })}
    </View>
  );
};

export default ListSteps;
