import React from 'react';
import { View, ViewStyle } from 'react-native';
import { tailwind } from '../../../common/styles/tailwind';

interface Props {
  children: React.ReactNode;
  style?: ViewStyle;
}

const PaymentRow = ({ children, style }: Props) => {
  return (
    <View style={{ ...tailwind('flex flex-row justify-between items-center'), ...style }}>
      {children}
    </View>
  );
};

export default PaymentRow;
