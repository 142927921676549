import React, { useState } from 'react';
import Layout from '../../../common/components/Layout';
import { H1 } from '../../../common/components/Typography';
import { useToast } from '../../../common/hooks/useToast';
import RootRoutes from '../../../common/navigation/RootRoutes';
import { useUserState } from '../../../common/state';
import { tailwind } from '../../../common/styles/tailwind';
import CarConfirm from '../components/CarConfirm';
import CarSearch from '../components/CarSearch';
import { getCar } from '../mockCarApi';
import { Car } from '../types';
import { carPlateInputRegex, carPlateRegex } from '../utils/regNrValidation';

const AddCar = ({ navigation }: any) => {
  const [regNr, setRegNr] = useState('');
  const [loading, setLoading] = useState(false);
  const [car, setCar] = useState<Car | null>(null);
  const addUserCar = useUserState((state) => state.addCar);
  const setSelectedCar = useUserState((state) => state.setSelectedCar);
  const toast = useToast();

  const onChangeText = (text: string) => {
    // Not sure if we should validate the input
    if (!carPlateInputRegex.test(text)) return;

    setRegNr(text.toUpperCase());
  };

  const addCar = async () => {
    if (!carPlateRegex.test(regNr)) {
      toast.warning('Ugyldig registreringsnr.', 'Er du sikker på at du skrev riktig?', {
        autoHide: true,
      });
      return;
    }
    setLoading(true);
    const carRes = await getCar(regNr);
    setCar(carRes);
    setLoading(false);
  };

  const confirmAddCar = (newCar: Car) => {
    addUserCar(newCar);
    setSelectedCar(newCar);
    navigation.navigate(RootRoutes.ADDCARCONFIRMATION, {
      car: newCar.kjennemerke,
    });
  };

  return (
    <Layout>
      <H1 style={tailwind('mb-4')}>LEGG TIL NY BIL</H1>
      {!car ? (
        <CarSearch addCar={addCar} loading={loading} onChangeText={onChangeText} regNr={regNr} />
      ) : (
        <CarConfirm car={car} confirmAddCar={confirmAddCar} setCar={setCar} />
      )}
    </Layout>
  );
};

export default AddCar;
