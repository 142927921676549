import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

export type AuthorizeClientInput = {
  providerSecret: Scalars['String'];
};

export type AuthorizeClientOutput = {
  __typename?: 'AuthorizeClientOutput';
  accessToken: Scalars['String'];
  expiresIn: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateOrderInput = {
  isMobile: Scalars['Boolean'];
  paymentChannel: Scalars['String'];
  paymentType?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  programId: Scalars['Int'];
  promoCode?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
};

export type CreateOrderOutput = {
  __typename?: 'CreateOrderOutput';
  actionUrl: Scalars['String'];
  orderId: Scalars['String'];
  washGrantId?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type StartProgramInput = {
  washGrantId: Scalars['uuid'];
};

export type StartProgramOutput = {
  __typename?: 'StartProgramOutput';
  status: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "companies" */
export type Companies = {
  __typename?: 'companies';
  id: Scalars['Int'];
  /** An array relationship */
  locations: Array<Locations>;
  name: Scalars['String'];
};


/** columns and relationships of "companies" */
export type CompaniesLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type CompaniesBoolExp = {
  _and?: InputMaybe<Array<CompaniesBoolExp>>;
  _not?: InputMaybe<CompaniesBoolExp>;
  _or?: InputMaybe<Array<CompaniesBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  locations?: InputMaybe<LocationsBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "companies". */
export type CompaniesOrderBy = {
  id?: InputMaybe<OrderBy>;
  locations_aggregate?: InputMaybe<LocationsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "companies" */
export enum CompaniesSelectColumn {
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  washers: Array<Washers>;
};


/** columns and relationships of "locations" */
export type LocationsWashersArgs = {
  distinct_on?: InputMaybe<Array<WashersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WashersOrderBy>>;
  where?: InputMaybe<WashersBoolExp>;
};

/** order by aggregate values of table "locations" */
export type LocationsAggregateOrderBy = {
  avg?: InputMaybe<LocationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LocationsMaxOrderBy>;
  min?: InputMaybe<LocationsMinOrderBy>;
  stddev?: InputMaybe<LocationsStddevOrderBy>;
  stddev_pop?: InputMaybe<LocationsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LocationsStddevSampOrderBy>;
  sum?: InputMaybe<LocationsSumOrderBy>;
  var_pop?: InputMaybe<LocationsVarPopOrderBy>;
  var_samp?: InputMaybe<LocationsVarSampOrderBy>;
  variance?: InputMaybe<LocationsVarianceOrderBy>;
};

/** order by avg() on columns of table "locations" */
export type LocationsAvgOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type LocationsBoolExp = {
  _and?: InputMaybe<Array<LocationsBoolExp>>;
  _not?: InputMaybe<LocationsBoolExp>;
  _or?: InputMaybe<Array<LocationsBoolExp>>;
  company?: InputMaybe<CompaniesBoolExp>;
  company_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lat?: InputMaybe<NumericComparisonExp>;
  lng?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  washers?: InputMaybe<WashersBoolExp>;
};

/** order by max() on columns of table "locations" */
export type LocationsMaxOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "locations" */
export type LocationsMinOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "locations". */
export type LocationsOrderBy = {
  company?: InputMaybe<CompaniesOrderBy>;
  company_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  washers_aggregate?: InputMaybe<WashersAggregateOrderBy>;
};

/** select columns of table "locations" */
export enum LocationsSelectColumn {
  /** column name */
  COMPANY_ID = 'company_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  LAT = 'lat',
  /** column name */
  LNG = 'lng',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** order by stddev() on columns of table "locations" */
export type LocationsStddevOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "locations" */
export type LocationsStddevPopOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "locations" */
export type LocationsStddevSampOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "locations" */
export type LocationsSumOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "locations" */
export type LocationsVarPopOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "locations" */
export type LocationsVarSampOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "locations" */
export type LocationsVarianceOrderBy = {
  company_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lat?: InputMaybe<OrderBy>;
  lng?: InputMaybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** Client authorization */
  authorizeClient?: Maybe<AuthorizeClientOutput>;
  createOrder?: Maybe<CreateOrderOutput>;
  startProgram?: Maybe<StartProgramOutput>;
};


/** mutation root */
export type MutationRootAuthorizeClientArgs = {
  input: AuthorizeClientInput;
};


/** mutation root */
export type MutationRootCreateOrderArgs = {
  input: CreateOrderInput;
};


/** mutation root */
export type MutationRootStartProgramArgs = {
  input: StartProgramInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Offers */
export type Offers = {
  __typename?: 'offers';
  day_of_week: Scalars['Int'];
  id: Scalars['Int'];
  is_enabled: Scalars['Boolean'];
  price: Scalars['Int'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['Int'];
};

/** order by aggregate values of table "offers" */
export type OffersAggregateOrderBy = {
  avg?: InputMaybe<OffersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OffersMaxOrderBy>;
  min?: InputMaybe<OffersMinOrderBy>;
  stddev?: InputMaybe<OffersStddevOrderBy>;
  stddev_pop?: InputMaybe<OffersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OffersStddevSampOrderBy>;
  sum?: InputMaybe<OffersSumOrderBy>;
  var_pop?: InputMaybe<OffersVarPopOrderBy>;
  var_samp?: InputMaybe<OffersVarSampOrderBy>;
  variance?: InputMaybe<OffersVarianceOrderBy>;
};

/** order by avg() on columns of table "offers" */
export type OffersAvgOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "offers". All fields are combined with a logical 'AND'. */
export type OffersBoolExp = {
  _and?: InputMaybe<Array<OffersBoolExp>>;
  _not?: InputMaybe<OffersBoolExp>;
  _or?: InputMaybe<Array<OffersBoolExp>>;
  day_of_week?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_enabled?: InputMaybe<BooleanComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  program?: InputMaybe<ProgramsBoolExp>;
  program_id?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "offers" */
export type OffersMaxOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "offers" */
export type OffersMinOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "offers". */
export type OffersOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_enabled?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program?: InputMaybe<ProgramsOrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** select columns of table "offers" */
export enum OffersSelectColumn {
  /** column name */
  DAY_OF_WEEK = 'day_of_week',
  /** column name */
  ID = 'id',
  /** column name */
  IS_ENABLED = 'is_enabled',
  /** column name */
  PRICE = 'price',
  /** column name */
  PROGRAM_ID = 'program_id'
}

/** order by stddev() on columns of table "offers" */
export type OffersStddevOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "offers" */
export type OffersStddevPopOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "offers" */
export type OffersStddevSampOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "offers" */
export type OffersSumOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "offers" */
export type OffersVarPopOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "offers" */
export type OffersVarSampOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "offers" */
export type OffersVarianceOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program_id?: InputMaybe<OrderBy>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

/** columns and relationships of "price_details" */
export type PriceDetails = {
  __typename?: 'price_details';
  offer_id?: Maybe<Scalars['Int']>;
  offer_price?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  /** An object relationship */
  program?: Maybe<Programs>;
  program_id?: Maybe<Scalars['Int']>;
  program_price?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "price_details". All fields are combined with a logical 'AND'. */
export type PriceDetailsBoolExp = {
  _and?: InputMaybe<Array<PriceDetailsBoolExp>>;
  _not?: InputMaybe<PriceDetailsBoolExp>;
  _or?: InputMaybe<Array<PriceDetailsBoolExp>>;
  offer_id?: InputMaybe<IntComparisonExp>;
  offer_price?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  program?: InputMaybe<ProgramsBoolExp>;
  program_id?: InputMaybe<IntComparisonExp>;
  program_price?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "price_details". */
export type PriceDetailsOrderBy = {
  offer_id?: InputMaybe<OrderBy>;
  offer_price?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  program?: InputMaybe<ProgramsOrderBy>;
  program_id?: InputMaybe<OrderBy>;
  program_price?: InputMaybe<OrderBy>;
};

/** select columns of table "price_details" */
export enum PriceDetailsSelectColumn {
  /** column name */
  OFFER_ID = 'offer_id',
  /** column name */
  OFFER_PRICE = 'offer_price',
  /** column name */
  PRICE = 'price',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  PROGRAM_PRICE = 'program_price'
}

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'programs';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  offers: Array<Offers>;
  /** An object relationship */
  price_details?: Maybe<PriceDetails>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  washer: Washers;
  washer_id: Scalars['Int'];
};


/** columns and relationships of "programs" */
export type ProgramsOffersArgs = {
  distinct_on?: InputMaybe<Array<OffersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OffersOrderBy>>;
  where?: InputMaybe<OffersBoolExp>;
};

/** order by aggregate values of table "programs" */
export type ProgramsAggregateOrderBy = {
  avg?: InputMaybe<ProgramsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProgramsMaxOrderBy>;
  min?: InputMaybe<ProgramsMinOrderBy>;
  stddev?: InputMaybe<ProgramsStddevOrderBy>;
  stddev_pop?: InputMaybe<ProgramsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ProgramsStddevSampOrderBy>;
  sum?: InputMaybe<ProgramsSumOrderBy>;
  var_pop?: InputMaybe<ProgramsVarPopOrderBy>;
  var_samp?: InputMaybe<ProgramsVarSampOrderBy>;
  variance?: InputMaybe<ProgramsVarianceOrderBy>;
};

/** order by avg() on columns of table "programs" */
export type ProgramsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type ProgramsBoolExp = {
  _and?: InputMaybe<Array<ProgramsBoolExp>>;
  _not?: InputMaybe<ProgramsBoolExp>;
  _or?: InputMaybe<Array<ProgramsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  external_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  offers?: InputMaybe<OffersBoolExp>;
  price_details?: InputMaybe<PriceDetailsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  washer?: InputMaybe<WashersBoolExp>;
  washer_id?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "programs" */
export type ProgramsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "programs" */
export type ProgramsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "programs". */
export type ProgramsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  offers_aggregate?: InputMaybe<OffersAggregateOrderBy>;
  price_details?: InputMaybe<PriceDetailsOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  washer?: InputMaybe<WashersOrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** select columns of table "programs" */
export enum ProgramsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  EXTERNAL_ID = 'external_id',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WASHER_ID = 'washer_id'
}

/** order by stddev() on columns of table "programs" */
export type ProgramsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "programs" */
export type ProgramsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "programs" */
export type ProgramsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "programs" */
export type ProgramsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "programs" */
export type ProgramsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "programs" */
export type ProgramsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "programs" */
export type ProgramsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  washer_id?: InputMaybe<OrderBy>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** An array relationship */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  offers: Array<Offers>;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table: "price_details" */
  price_details: Array<PriceDetails>;
  /** An array relationship */
  programs: Array<Programs>;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "washer_types" */
  washer_types: Array<WasherTypes>;
  /** fetch data from the table: "washer_types" using primary key columns */
  washer_types_by_pk?: Maybe<WasherTypes>;
  /** An array relationship */
  washers: Array<Washers>;
  /** fetch data from the table: "washers" using primary key columns */
  washers_by_pk?: Maybe<Washers>;
};


export type QueryRootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<CompaniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompaniesOrderBy>>;
  where?: InputMaybe<CompaniesBoolExp>;
};


export type QueryRootCompaniesByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};


export type QueryRootLocationsByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootOffersArgs = {
  distinct_on?: InputMaybe<Array<OffersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OffersOrderBy>>;
  where?: InputMaybe<OffersBoolExp>;
};


export type QueryRootOffersByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootPriceDetailsArgs = {
  distinct_on?: InputMaybe<Array<PriceDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceDetailsOrderBy>>;
  where?: InputMaybe<PriceDetailsBoolExp>;
};


export type QueryRootProgramsArgs = {
  distinct_on?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};


export type QueryRootProgramsByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootWasherTypesArgs = {
  distinct_on?: InputMaybe<Array<WasherTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WasherTypesOrderBy>>;
  where?: InputMaybe<WasherTypesBoolExp>;
};


export type QueryRootWasherTypesByPkArgs = {
  name: Scalars['String'];
};


export type QueryRootWashersArgs = {
  distinct_on?: InputMaybe<Array<WashersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WashersOrderBy>>;
  where?: InputMaybe<WashersBoolExp>;
};


export type QueryRootWashersByPkArgs = {
  id: Scalars['Int'];
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** An array relationship */
  locations: Array<Locations>;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  offers: Array<Offers>;
  /** fetch data from the table: "offers" using primary key columns */
  offers_by_pk?: Maybe<Offers>;
  /** fetch data from the table: "price_details" */
  price_details: Array<PriceDetails>;
  /** An array relationship */
  programs: Array<Programs>;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "washer_types" */
  washer_types: Array<WasherTypes>;
  /** fetch data from the table: "washer_types" using primary key columns */
  washer_types_by_pk?: Maybe<WasherTypes>;
  /** An array relationship */
  washers: Array<Washers>;
  /** fetch data from the table: "washers" using primary key columns */
  washers_by_pk?: Maybe<Washers>;
};


export type SubscriptionRootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<CompaniesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompaniesOrderBy>>;
  where?: InputMaybe<CompaniesBoolExp>;
};


export type SubscriptionRootCompaniesByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationsOrderBy>>;
  where?: InputMaybe<LocationsBoolExp>;
};


export type SubscriptionRootLocationsByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootOffersArgs = {
  distinct_on?: InputMaybe<Array<OffersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OffersOrderBy>>;
  where?: InputMaybe<OffersBoolExp>;
};


export type SubscriptionRootOffersByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootPriceDetailsArgs = {
  distinct_on?: InputMaybe<Array<PriceDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceDetailsOrderBy>>;
  where?: InputMaybe<PriceDetailsBoolExp>;
};


export type SubscriptionRootProgramsArgs = {
  distinct_on?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};


export type SubscriptionRootProgramsByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootWasherTypesArgs = {
  distinct_on?: InputMaybe<Array<WasherTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WasherTypesOrderBy>>;
  where?: InputMaybe<WasherTypesBoolExp>;
};


export type SubscriptionRootWasherTypesByPkArgs = {
  name: Scalars['String'];
};


export type SubscriptionRootWashersArgs = {
  distinct_on?: InputMaybe<Array<WashersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WashersOrderBy>>;
  where?: InputMaybe<WashersBoolExp>;
};


export type SubscriptionRootWashersByPkArgs = {
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "washer_types" */
export type WasherTypes = {
  __typename?: 'washer_types';
  name: Scalars['String'];
  /** An array relationship */
  washers: Array<Washers>;
};


/** columns and relationships of "washer_types" */
export type WasherTypesWashersArgs = {
  distinct_on?: InputMaybe<Array<WashersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WashersOrderBy>>;
  where?: InputMaybe<WashersBoolExp>;
};

/** Boolean expression to filter rows from the table "washer_types". All fields are combined with a logical 'AND'. */
export type WasherTypesBoolExp = {
  _and?: InputMaybe<Array<WasherTypesBoolExp>>;
  _not?: InputMaybe<WasherTypesBoolExp>;
  _or?: InputMaybe<Array<WasherTypesBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
  washers?: InputMaybe<WashersBoolExp>;
};

export enum WasherTypesEnum {
  CONVEYOR = 'conveyor',
  SELF_SERVICE = 'self_service'
}

/** Boolean expression to compare columns of type "washer_types_enum". All fields are combined with logical 'AND'. */
export type WasherTypesEnumComparisonExp = {
  _eq?: InputMaybe<WasherTypesEnum>;
  _in?: InputMaybe<Array<WasherTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WasherTypesEnum>;
  _nin?: InputMaybe<Array<WasherTypesEnum>>;
};

/** Ordering options when selecting data from "washer_types". */
export type WasherTypesOrderBy = {
  name?: InputMaybe<OrderBy>;
  washers_aggregate?: InputMaybe<WashersAggregateOrderBy>;
};

/** select columns of table "washer_types" */
export enum WasherTypesSelectColumn {
  /** column name */
  NAME = 'name'
}

/** columns and relationships of "washers" */
export type Washers = {
  __typename?: 'washers';
  created_at: Scalars['timestamptz'];
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  programs: Array<Programs>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  washerTypeByWasherType: WasherTypes;
  washer_type: WasherTypesEnum;
};


/** columns and relationships of "washers" */
export type WashersProgramsArgs = {
  distinct_on?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

/** order by aggregate values of table "washers" */
export type WashersAggregateOrderBy = {
  avg?: InputMaybe<WashersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WashersMaxOrderBy>;
  min?: InputMaybe<WashersMinOrderBy>;
  stddev?: InputMaybe<WashersStddevOrderBy>;
  stddev_pop?: InputMaybe<WashersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WashersStddevSampOrderBy>;
  sum?: InputMaybe<WashersSumOrderBy>;
  var_pop?: InputMaybe<WashersVarPopOrderBy>;
  var_samp?: InputMaybe<WashersVarSampOrderBy>;
  variance?: InputMaybe<WashersVarianceOrderBy>;
};

/** order by avg() on columns of table "washers" */
export type WashersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "washers". All fields are combined with a logical 'AND'. */
export type WashersBoolExp = {
  _and?: InputMaybe<Array<WashersBoolExp>>;
  _not?: InputMaybe<WashersBoolExp>;
  _or?: InputMaybe<Array<WashersBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  external_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<LocationsBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  programs?: InputMaybe<ProgramsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  washerTypeByWasherType?: InputMaybe<WasherTypesBoolExp>;
  washer_type?: InputMaybe<WasherTypesEnumComparisonExp>;
};

/** order by max() on columns of table "washers" */
export type WashersMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "washers" */
export type WashersMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "washers". */
export type WashersOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  external_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationsOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  programs_aggregate?: InputMaybe<ProgramsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  washerTypeByWasherType?: InputMaybe<WasherTypesOrderBy>;
  washer_type?: InputMaybe<OrderBy>;
};

/** select columns of table "washers" */
export enum WashersSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXTERNAL_ID = 'external_id',
  /** column name */
  ID = 'id',
  /** column name */
  LOCATION_ID = 'location_id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WASHER_TYPE = 'washer_type'
}

/** order by stddev() on columns of table "washers" */
export type WashersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "washers" */
export type WashersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "washers" */
export type WashersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "washers" */
export type WashersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "washers" */
export type WashersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "washers" */
export type WashersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "washers" */
export type WashersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

export type LocationFragment = { __typename?: 'locations', id: number, name: string, company: { __typename?: 'companies', id: number, name: string } };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: number, name: string, washers: Array<{ __typename?: 'washers', id: number, name: string, washer_type: WasherTypesEnum, programs: Array<{ __typename?: 'programs', id: number, name: string, description?: string | null, price_details?: { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null } | null }> }>, company: { __typename?: 'companies', id: number, name: string } }> };

export type GetLocationByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLocationByIdQuery = { __typename?: 'query_root', locations_by_pk?: { __typename?: 'locations', id: number, name: string, washers: Array<{ __typename?: 'washers', id: number, name: string, washer_type: WasherTypesEnum, programs: Array<{ __typename?: 'programs', id: number, name: string, description?: string | null, price_details?: { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null } | null }> }>, company: { __typename?: 'companies', id: number, name: string } } | null };

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'mutation_root', createOrder?: { __typename?: 'CreateOrderOutput', actionUrl: string, orderId: string, washGrantId?: any | null } | null };

export type PriceDetailsFragment = { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null };

export type ProgramFragment = { __typename?: 'programs', id: number, name: string, description?: string | null, price_details?: { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null } | null };

export type StartProgramMutationVariables = Exact<{
  washGrantId: Scalars['uuid'];
}>;


export type StartProgramMutation = { __typename?: 'mutation_root', startProgram?: { __typename?: 'StartProgramOutput', status: string } | null };

export type WasherFragment = { __typename?: 'washers', id: number, name: string, washer_type: WasherTypesEnum };

export type GetWashersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWashersQuery = { __typename?: 'query_root', washers: Array<{ __typename?: 'washers', id: number, name: string, washer_type: WasherTypesEnum, location: { __typename?: 'locations', id: number, name: string, company: { __typename?: 'companies', id: number, name: string } }, programs: Array<{ __typename?: 'programs', id: number, name: string, description?: string | null, price_details?: { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null } | null }> }> };

export type GetWasherByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetWasherByIdQuery = { __typename?: 'query_root', washers: Array<{ __typename?: 'washers', id: number, name: string, washer_type: WasherTypesEnum, programs: Array<{ __typename?: 'programs', id: number, name: string, description?: string | null, price_details?: { __typename?: 'price_details', price?: number | null, program_price?: number | null, offer_price?: number | null } | null }> }> };

export const LocationFragmentDoc = gql`
    fragment Location on locations {
  id
  name
  company {
    id
    name
  }
}
    `;
export const PriceDetailsFragmentDoc = gql`
    fragment PriceDetails on price_details {
  price
  program_price
  offer_price
}
    `;
export const ProgramFragmentDoc = gql`
    fragment Program on programs {
  id
  name
  description
  price_details {
    ...PriceDetails
  }
}
    `;
export const WasherFragmentDoc = gql`
    fragment Washer on washers {
  id
  name
  washer_type
}
    `;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    ...Location
    washers {
      ...Washer
      programs {
        ...Program
      }
    }
  }
}
    ${LocationFragmentDoc}
${WasherFragmentDoc}
${ProgramFragmentDoc}
${PriceDetailsFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationByIdDocument = gql`
    query GetLocationById($id: Int!) {
  locations_by_pk(id: $id) {
    ...Location
    washers {
      ...Washer
      programs {
        ...Program
      }
    }
  }
}
    ${LocationFragmentDoc}
${WasherFragmentDoc}
${ProgramFragmentDoc}
${PriceDetailsFragmentDoc}`;

/**
 * __useGetLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
      }
export function useGetLocationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
        }
export type GetLocationByIdQueryHookResult = ReturnType<typeof useGetLocationByIdQuery>;
export type GetLocationByIdLazyQueryHookResult = ReturnType<typeof useGetLocationByIdLazyQuery>;
export type GetLocationByIdQueryResult = Apollo.QueryResult<GetLocationByIdQuery, GetLocationByIdQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    actionUrl
    orderId
    washGrantId
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const StartProgramDocument = gql`
    mutation StartProgram($washGrantId: uuid!) {
  startProgram(input: {washGrantId: $washGrantId}) {
    status
  }
}
    `;
export type StartProgramMutationFn = Apollo.MutationFunction<StartProgramMutation, StartProgramMutationVariables>;

/**
 * __useStartProgramMutation__
 *
 * To run a mutation, you first call `useStartProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startProgramMutation, { data, loading, error }] = useStartProgramMutation({
 *   variables: {
 *      washGrantId: // value for 'washGrantId'
 *   },
 * });
 */
export function useStartProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartProgramMutation, StartProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartProgramMutation, StartProgramMutationVariables>(StartProgramDocument, options);
      }
export type StartProgramMutationHookResult = ReturnType<typeof useStartProgramMutation>;
export type StartProgramMutationResult = Apollo.MutationResult<StartProgramMutation>;
export type StartProgramMutationOptions = Apollo.BaseMutationOptions<StartProgramMutation, StartProgramMutationVariables>;
export const GetWashersDocument = gql`
    query GetWashers {
  washers {
    ...Washer
    location {
      ...Location
    }
    programs {
      ...Program
    }
  }
}
    ${WasherFragmentDoc}
${LocationFragmentDoc}
${ProgramFragmentDoc}
${PriceDetailsFragmentDoc}`;

/**
 * __useGetWashersQuery__
 *
 * To run a query within a React component, call `useGetWashersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWashersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWashersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWashersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWashersQuery, GetWashersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWashersQuery, GetWashersQueryVariables>(GetWashersDocument, options);
      }
export function useGetWashersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWashersQuery, GetWashersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWashersQuery, GetWashersQueryVariables>(GetWashersDocument, options);
        }
export type GetWashersQueryHookResult = ReturnType<typeof useGetWashersQuery>;
export type GetWashersLazyQueryHookResult = ReturnType<typeof useGetWashersLazyQuery>;
export type GetWashersQueryResult = Apollo.QueryResult<GetWashersQuery, GetWashersQueryVariables>;
export const GetWasherByIdDocument = gql`
    query GetWasherById($id: Int) {
  washers(where: {id: {_eq: $id}}) {
    ...Washer
    programs {
      ...Program
    }
  }
}
    ${WasherFragmentDoc}
${ProgramFragmentDoc}
${PriceDetailsFragmentDoc}`;

/**
 * __useGetWasherByIdQuery__
 *
 * To run a query within a React component, call `useGetWasherByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWasherByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWasherByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWasherByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWasherByIdQuery, GetWasherByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWasherByIdQuery, GetWasherByIdQueryVariables>(GetWasherByIdDocument, options);
      }
export function useGetWasherByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWasherByIdQuery, GetWasherByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWasherByIdQuery, GetWasherByIdQueryVariables>(GetWasherByIdDocument, options);
        }
export type GetWasherByIdQueryHookResult = ReturnType<typeof useGetWasherByIdQuery>;
export type GetWasherByIdLazyQueryHookResult = ReturnType<typeof useGetWasherByIdLazyQuery>;
export type GetWasherByIdQueryResult = Apollo.QueryResult<GetWasherByIdQuery, GetWasherByIdQueryVariables>;