import React from 'react';
import { TextStyle, TouchableOpacity } from 'react-native';
import { tailwind } from '../styles/tailwind';
import { swashBorder, swashColor } from '../styles/types';
import LoadingIndicator from './LoadingIndicator';
import { BodyText } from './Typography';

interface ButtonProps {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  stretch?: boolean;
  style?: TextStyle;
  backgroundColor?: swashColor;
  border?: swashBorder;
}

const Button = ({
  title,
  onPress,
  stretch = false,
  style,
  disabled,
  loading,
  backgroundColor = 'swash-water',
  border,
}: ButtonProps) => {
  const borderStyle = border ? `${border} border-swash-snow border` : '';
  const backgroundColorStyle = `bg-${backgroundColor}`;
  const stretchClass = stretch ? '' : 'self-start';
  const disabledClass = disabled || loading ? 'bg-swash-water-disabled' : '';
  const disabledClassText = disabled || loading ? 'text-swash-snow opacity-50' : '';

  return (
    <TouchableOpacity
      disabled={disabled || loading}
      onPress={onPress}
      style={{
        ...tailwind(
          `h-16 flex p-5 justify-center rounded-xl ${stretchClass} ${disabledClass} ${backgroundColorStyle} ${borderStyle}`
        ),
        ...style,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <BodyText style={tailwind(`text-center ${disabledClassText}`)}>{title}</BodyText>
      )}
    </TouchableOpacity>
  );
};

export default Button;
