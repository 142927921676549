import React, { useEffect } from 'react';
import FlashMessage, { showMessage, hideMessage } from 'react-native-flash-message';
import { useMessageState } from '../state';

export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export interface IMessage {
  message: string;
  description?: string;
  type: MessageType;
  options?: IMessageOptions;
}

export interface IMessageOptions {
  autoHide?: boolean;
}

const MessageComponent = React.memo(() => (
  <FlashMessage position={'top'} textStyle={{ paddingRight: 20 }} />
));
MessageComponent.displayName = 'MessageComponent';

const Messages = () => {
  const message = useMessageState((state) => state.message);
  const clearMessage = useMessageState((state) => state.clearMessage);

  useEffect(() => {
    if (!message || !message.message) {
      hideMessage();
    } else {
      showMessage({
        message: message.message,
        description: message.description,
        type: message.type,
        icon: message.type,
        autoHide: message.options?.autoHide,
        onPress: clearMessage,
      });
    }
  }, [message, clearMessage]);
  return <MessageComponent />;
};

export default Messages;
