import React from 'react';
import { View } from 'react-native';
import VippsMark from '../../../common/assets/logos/VippsMark';
import { BodySmall, BodyText } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';
import PaymentRow from './PaymentRow';

interface Props {
  locationName: string;
  programName: string;
  price: number;
}

const WashPaymentSummary = ({ locationName, programName, price }: Props) => {
  return (
    <View>
      <View style={tailwind('bg-swash-deep-ocean bg-opacity-50 rounded-xl p-4')}>
        <PaymentRow>
          <BodySmall>Program</BodySmall>
          <BodySmall style={tailwind('capitalize')}>{programName}</BodySmall>
        </PaymentRow>
        <PaymentRow style={tailwind('mt-4')}>
          <BodySmall>Lokasjon</BodySmall>
          <BodySmall>{locationName}</BodySmall>
        </PaymentRow>
        <PaymentRow style={tailwind('mt-4')}>
          <BodySmall>Betalingsmetode</BodySmall>
          <View style={tailwind('flex flex-row items-center')}>
            <BodySmall style={tailwind('mr-2')}>Vipps</BodySmall>
            <VippsMark />
          </View>
        </PaymentRow>
      </View>
      <View style={tailwind('bg-swash-deep-ocean bg-opacity-50 rounded-xl p-4 mt-4')}>
        <PaymentRow>
          <BodyText>Total</BodyText>
          <BodyText>KR {price},-</BodyText>
        </PaymentRow>
      </View>
    </View>
  );
};

export default WashPaymentSummary;
