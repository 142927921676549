import React, { useEffect, useState } from 'react';
import Input from '../../../common/components/Input';
import KeyboardAvoidingViewSwash from '../../../common/components/KeyboardAvoidingViewSwash';
import { stringToPrice } from '../../../common/utils/price';

interface NumberInputProps {
  minPrice: number;
  maxPrice: number;
}

const useNumberInput = ({ minPrice, maxPrice }: NumberInputProps) => {
  const [input, setInput] = useState('');
  const [amount, setAmount] = useState<number>();
  const [validAmount, setValidAmount] = useState(false);

  useEffect(() => {
    const newAmount = stringToPrice(input);
    if (newAmount) {
      setAmount(newAmount);
    }
  }, [input]);

  // TODO: Refactor to accept a generic predicate
  useEffect(() => {
    if (amount && amount >= minPrice && amount <= maxPrice) {
      setValidAmount(true);
    } else {
      setValidAmount(false);
    }
  }, [amount, minPrice, maxPrice]);

  const updateInput = (text: string) => {
    if (Number.isNaN(Number(text))) return;
    // Trim the text to remove whitespace
    setInput(text.trim());
  };

  return { updateInput, input, amount, validAmount };
};

interface Props {
  minPrice: number;
  maxPrice: number;
  onSelectCard: (amount: number, programId?: number) => void;
}

const AmountInput = ({ onSelectCard, minPrice, maxPrice }: Props) => {
  const { updateInput, input, amount, validAmount } = useNumberInput({ minPrice, maxPrice });

  return (
    <KeyboardAvoidingViewSwash>
      <Input>
        <Input.Field
          placeholder="Eget beløp"
          onChangeText={updateInput}
          value={input}
          onSubmitEditing={() => onSelectCard(amount as number)}
        />
        <Input.Button
          disabled={!input || !validAmount}
          // Amount will always be a number
          onPress={() => onSelectCard(amount as number)}
          title={`${input ? input + ',-' : '...'}`}
        />
      </Input>
    </KeyboardAvoidingViewSwash>
  );
};

export default AmountInput;
