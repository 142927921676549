import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import Layout from '../../../common/components/Layout';
import ScreenError from '../../../common/components/ScreenError';
import ScreenLoader from '../../../common/components/ScreenLoader';
import ScrollableContent from '../../../common/components/ScrollableContent';
import { H1, BodyText } from '../../../common/components/Typography';
import { useAppState } from '../../../common/state';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import { tailwind } from '../../../common/styles/tailwind';
import LocationSelector from '../components/LocationSelector';
import WashRoutes from '../WashRoutes';

const WashLanding = ({
  navigation,
  route,
}: NativeStackScreenProps<any, WashRoutes.WASHLANDING>) => {
  const { isLoadingQueryParams } = useQueryPopulation([], route);
  const locations = useAppState((state) => state.locations);

  if (isLoadingQueryParams) return <ScreenLoader />;
  if (!locations) {
    return <ScreenError />;
  }

  return (
    <Layout withPadding={false}>
      <View style={tailwind('px-4 pt-6')}>
        <H1>VASK BILEN PÅ 1-2-3</H1>
        <BodyText>Velg en vaskehall</BodyText>
      </View>
      <ScrollableContent>
        <LocationSelector locations={locations} navigation={navigation} />
      </ScrollableContent>
    </Layout>
  );
};

export default WashLanding;
