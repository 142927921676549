import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import Divider from '../../../common/components/Divider';
import ScreenError from '../../../common/components/ScreenError';
import Layout from '../../../common/components/Layout';
import ScreenLoader from '../../../common/components/ScreenLoader';
import { WasherTypesEnum } from '../../../common/graphql/generated/apollo';
import { Swash } from '../../../common/graphql/types';
import { useAppState } from '../../../common/state';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import { tailwind } from '../../../common/styles/tailwind';
import AmountInput from '../../payment/components/AmountInput';
import WashCard from '../components/WashCard';
import WashRoutes from '../WashRoutes';
import washerMinPrice from '../utils/programsMinPrice';
import ScrollableContent from '../../../common/components/ScrollableContent';
import { getProgramPrice } from '../../../common/utils/price';

// TODO: get from api in the future
const selfWashPriceOptions: PriceSelectorOption[] = [
  {
    name: 'Litt skitten',
    description: '3-7 min',
    price: 5000,
  },
  {
    name: 'Ganske skitten',
    description: '5-9 min',
    price: 10000,
  },
  {
    name: 'Veldig skitten',
    description: '7-11 min',
    price: 15000,
  },
];

const WashProgram = ({
  route,
  navigation,
}: NativeStackScreenProps<any, WashRoutes.WASHPROGRAM>) => {
  const { isLoadingQueryParams } = useQueryPopulation(['id', 'washer'], route);
  const locations = useAppState((state) => state.locations);
  const location = useAppState((state) => state.location);
  const washer = useAppState((state) => state.washer);
  const setProgramPrice = useAppState((state) => state.setProgramPrice);
  const setWasherProgram = useAppState((state) => state.setWasherProgram);
  const getWasherProgramById = useAppState((state) => state.getWasherProgramById);

  const onSelectCard = (amount: number, programId?: number) => {
    setProgramPrice(amount);
    if (programId) {
      const program = getWasherProgramById(programId);
      setWasherProgram(program);
    }
    navigation.push(WashRoutes.WASHSUMMARY, {
      id: location?.id,
      washer: washer?.id,
      program: programId ?? washer?.programs[0].id, // TODO: Assumes that every self_service has only one program
      price: amount,
    });
  };

  if (isLoadingQueryParams) return <ScreenLoader />;
  if (!locations || !location || !washer) return <ScreenError />;

  const selfServiceMinPrice = washerMinPrice(washer);

  return (
    <Layout withPadding={false}>
      <ScrollableContent style={tailwind('mt-8')}>
        {washer.washer_type === WasherTypesEnum.SELF_SERVICE ? (
          <PriceSelector
            options={selfWashPriceOptions}
            onSelectCard={onSelectCard}
            minPrice={selfServiceMinPrice}
          />
        ) : (
          <ProgramSelector washer={washer} onSelectCard={onSelectCard} />
        )}
      </ScrollableContent>
    </Layout>
  );
};

interface WashProps {
  washer: Swash.Washer;
  onSelectCard: (amount: number, programId?: number) => void;
}

const ProgramSelector = ({ washer, onSelectCard }: WashProps) => {
  const sortedPrograms = washer.programs.sort((a, b) => getProgramPrice(a) - getProgramPrice(b));
  return (
    <View>
      {sortedPrograms.map((program, i) => {
        return (
          <WashCard
            name={program.name}
            description={program.description ?? 'Maskinvask'}
            programId={program.id}
            price={program.price_details?.program_price ?? 0}
            offerPrice={program.price_details?.offer_price ?? 0}
            key={program.id}
            rating={i + 1}
            firstElement={i === 0}
            onPress={onSelectCard}
          />
        );
      })}
    </View>
  );
};

interface PriceSelectorOption {
  name: string;
  description: string;
  price: number;
}

interface PriceSelectorProps {
  options: PriceSelectorOption[];
  onSelectCard: (amount: number, programId?: number) => void;
  minPrice: number;
}

const PriceSelector = ({ options, onSelectCard, minPrice }: PriceSelectorProps) => {
  return (
    <View>
      {options.map((option, i) => (
        <WashCard
          name={option.name}
          description={option.description}
          price={option.price}
          key={option.price}
          rating={i + 1}
          firstElement={i === 0}
          onPress={onSelectCard}
        />
      ))}
      <Divider title="eller" />
      <AmountInput onSelectCard={onSelectCard} minPrice={minPrice} maxPrice={100000} />
    </View>
  );
};

export default WashProgram;
