import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppLoading from 'expo-app-loading';

import Navigation from './common/navigation';
import { appConfig } from './common/config/appConfig';
import Messages from './common/components/Messages';

export default function App() {
  // Together with font-styles.json, enables use of custom fonts written w/Tailwind
  const [fontLoaded] = useFonts({
    // eslint-disable-next-line global-require
    FKScreamer: require('./common/assets/fonts/FKScreamer.ttf'),
    // eslint-disable-next-line global-require
    Montreal: require('./common/assets/fonts/Montreal.ttf'),
    // eslint-disable-next-line global-require
    'Montreal-Bold': require('./common/assets/fonts/Montreal-Bold.ttf'),
  });

  const createApolloClient = () => {
    return new ApolloClient({
      uri: appConfig.env.HASURA_GRAPHQL_URL,
      cache: new InMemoryCache(),
    });
  };

  if (!fontLoaded) {
    return <AppLoading />;
  }

  return (
    <ApolloProvider client={createApolloClient()}>
      <SafeAreaProvider>
        <Messages />
        <Navigation />
        <StatusBar />
      </SafeAreaProvider>
    </ApolloProvider>
  );
}
