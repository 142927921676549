import React from 'react';
import { KeyboardAvoidingView, Platform, ViewStyle } from 'react-native';

interface Props {
  children: React.ReactNode;
  style?: ViewStyle;
}

const KeyboardAvoidingViewSwash = ({ children, style }: Props) => {
  return (
    <KeyboardAvoidingView
      style={{ ...style }}
      behavior={Platform.OS === 'ios' ? 'position' : 'height'}
      // TODO: Make sure this is correct for all devices. Have only tested iPhone 13 Pro Max.
      // Tried implementing with keyboardHeightListener, but I need the height before the keyboard opens, which is not possible with keyboardHeightListener.
      keyboardVerticalOffset={Platform.OS === 'ios' ? 150 : 100}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default KeyboardAvoidingViewSwash;
