import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { BodyText } from '../../../components/Typography';
import { tailwind } from '../../../styles/tailwind';

interface Props {
  children: React.ReactNode[];
}

const Breadcrumbs = ({ children }: Props) => {
  const isLastChild = (index: number) => index !== children.length - 1;

  return (
    <View style={tailwind('flex-row mx-4')}>
      {children.map((child, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <View style={tailwind('flex-row')} key={index}>
            {child}
            {isLastChild(index) && <BodyText style={tailwind('opacity-50')}>{' > '}</BodyText>}
          </View>
        );
      })}
    </View>
  );
};

interface CrumbProps {
  title: string;
  isActive: boolean;
  disabled: boolean;
  onPress: () => void;
}

const Crumb = ({ title: routeName, onPress, isActive, disabled }: CrumbProps) => {
  const isActiveClass = isActive ? '' : 'opacity-50';

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <BodyText style={tailwind(`${isActiveClass}`)}>{routeName}</BodyText>
    </TouchableOpacity>
  );
};

Breadcrumbs.Crumb = Crumb;

export default Breadcrumbs;
