import * as React from 'react';
import { Path, Svg, Rect } from 'react-native-svg';

// The raw .svg-file is from https://github.com/vippsas/vipps-design-guidelines/blob/master/vipps-buttons/Vipps%20mark/vipps_mark.svg
const VippsMark = () => {
  return (
    <Svg fill="none" height="24" viewBox="0 0 48 32" width="36">
      <Rect fill="#ff5b24" height="32" rx="3.5" width="48" />
      <Path
        clipRule="evenodd"
        d="m27.864 12.2625c1.377 0 2.5571-1.0454 2.5571-2.548h.0003c0-1.50296-1.1804-2.548-2.5574-2.548-1.3767 0-2.5565 1.04504-2.5565 2.548 0 1.5026 1.1798 2.548 2.5565 2.548zm3.3437 4.1169c-1.705 2.221-3.5079 3.7566-6.6879 3.7566h.0004c-3.2444 0-5.7691-1.9602-7.7358-4.8349-.787-1.1763-1.9999-1.4375-2.8851-.8168-.8194.5882-1.0156 1.8296-.2621 2.9079 2.7206 4.149 6.4902 6.5661 10.8826 6.5661 4.0325 0 7.1796-1.9598 9.6378-5.2269.9176-1.2086.8849-2.45 0-3.1362-.8198-.654-2.0326-.4243-2.9499.7842z"
        fill="#fff"
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default VippsMark;
