import { GetLocationsQuery } from './generated/apollo';

type ArrayElement<T> = T extends (infer U)[] ? U : never;

export namespace Swash {
  // Typescript can't handle Lookup Types of nested interfaces, if the type can also be undefined. Have to infer the types from queries which guarantee return object.
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html#keyof-and-lookup-types

  export type Location = ArrayElement<GetLocationsQuery['locations']>;
  export type Washer = ArrayElement<Location['washers']>;
  export type WasherProgram = ArrayElement<Washer['programs']>;

  export enum AdyenVippsResponseCode {
    AUTHORISED = 'Authorised',
    CANCELLED = 'Cancelled',
    ERROR = 'Error',
    REFUSED = 'Refused',
  }

  // TODO: Get this from the scheme
  export enum StartProgramStatus {
    STARTED = 'started',
    USED = 'washGrantUsed',
    EXPIRED = 'washGrantExpired',
  }

  // The description will be '5-10 min' or 'Bilen blir polert'
  export interface ExtendedWasherProgram extends WasherProgram {
    description: string;
  }
}
