import React from 'react';
import { View } from 'react-native';
import { tailwind } from '../styles/tailwind';
import { BodyText } from './Typography';

interface Props {
  title?: string;
  color?: string;
}

const Line = ({ color }: { color: string | undefined }) => (
  <View
    style={{
      ...tailwind(`h-0.5 flex-1 rounded-full ${color ? `bg-${color}` : 'bg-swash-snow'}`),
      height: 1,
    }}
  />
);

const Divider = ({ title, color }: Props) => {
  const DividerWithoutTitle = <Line color={color} />;
  const DividerWithTitle = (
    <>
      <Line color={color} />
      <BodyText
        style={{
          ...tailwind(`mx-4 ${color ? `text-${color}` : 'text-swash-snow'}`),
          lineHeight: 0,
        }}
      >
        {title}
      </BodyText>
      <Line color={color} />
    </>
  );

  return (
    <View style={tailwind('flex-row justify-between w-full my-6 items-center')}>
      {title ? DividerWithTitle : DividerWithoutTitle}
    </View>
  );
};

export default Divider;
