import * as React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { tailwind } from '../styles/tailwind';
import NotFoundScreen from '../screens/NotFoundScreen';
import WashNavigator from '../../domain/wash/WashNavigator';
import Login from '../../domain/auth/screens/Login';
import AddCar from '../../domain/car/screens/AddCar';
import AddedCarConfirmation from '../../domain/car/screens/AddCarConfirmation';
import { RootStackParamList } from './navigationTypes';
import LinkingConfiguration from './LinkingConfiguration';
import RootRoutes from './RootRoutes';
import Header from './components/header/Header';

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration} theme={navTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const RootStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <RootStack.Navigator initialRouteName={RootRoutes.LOGIN}>
      <RootStack.Group
        screenOptions={{
          headerShadowVisible: false,
          headerStyle: tailwind('bg-swash-saphire'),
          headerTitle: '',
          header: () => <Header />,
          contentStyle: tailwind('bg-swash-saphire'),
        }}
      >
        <RootStack.Screen name={RootRoutes.ROOT} component={WashNavigator} />
        <RootStack.Screen name={RootRoutes.LOGIN} component={Login} />
        <RootStack.Screen name={RootRoutes.ADDCAR} component={AddCar} />
        <RootStack.Screen name={RootRoutes.ADDCARCONFIRMATION} component={AddedCarConfirmation} />
        <RootStack.Screen name={RootRoutes.NOTFOUND} component={NotFoundScreen} />
      </RootStack.Group>
    </RootStack.Navigator>
  );
}
