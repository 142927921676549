import React from 'react';
import { Text, TextStyle } from 'react-native';
import { tailwind } from '../styles/tailwind';

interface Props {
  children: React.ReactNode;
  style?: TextStyle;
}

export const H1 = ({ children, style }: Props) => {
  return (
    <Text style={{ ...tailwind(`text-white font-fk-screamer my-4`), fontSize: 48, ...style }}>
      {children}
    </Text>
  );
};

export const H2 = ({ children, style }: Props) => {
  return (
    <Text style={{ ...tailwind('text-white font-fk-screamer text-4xl my-2'), ...style }}>
      {children}
    </Text>
  );
};

export const H3 = ({ children, style }: Props) => {
  return (
    <Text style={{ ...tailwind('text-white font-montreal text-2xl my-2'), ...style }}>
      {children}
    </Text>
  );
};

export const BodyText = ({ children, style }: Props) => {
  return (
    <Text style={{ ...tailwind('text-white font-montreal text-lg'), ...style }}>{children}</Text>
  );
};

export const BodySmall = ({ children, style }: Props) => {
  return (
    <Text style={{ ...tailwind('text-white font-montreal text-sm'), ...style }}>{children}</Text>
  );
};
