import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable } from 'react-native';
import WashRoutes from '../../../../domain/wash/WashRoutes';
import SwashLogo from '../../../assets/logos/SwashLogo';
import { tailwind } from '../../../styles/tailwind';

const HeaderLeft = () => {
  const { navigate } = useNavigation<any>();

  return (
    <Pressable onPress={() => navigate(WashRoutes.WASHLANDING)} style={tailwind('ml-4')}>
      <SwashLogo />
    </Pressable>
  );
};

export default HeaderLeft;
