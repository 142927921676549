import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Button from '../../../common/components/Button';
import Layout from '../../../common/components/Layout';
import { H1, H3 } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';
import { useStartProgramMutation, WasherTypesEnum } from '../../../common/graphql/generated/apollo';
import { useAppState } from '../../../common/state';
import ScreenLoader from '../../../common/components/ScreenLoader';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import { Swash } from '../../../common/graphql/types.d';
import ListSteps from '../../../common/components/ListSteps';
import { useToast } from '../../../common/hooks/useToast';
import { IAppState } from '../../../common/state/types';

function findWasher(locations: IAppState['locations'], washerId: number) {
  const locationLength = locations?.length;
  if (locationLength) {
    for (let i = 0; i < locationLength; i++) {
      const location = locations[i];
      const washer = location.washers.find((w) => w.id === washerId);
      if (washer) {
        return washer;
      }
    }
  }
  return null;
}

const WashConfirmation = ({ route }: any) => {
  const { isLoadingQueryParams } = useQueryPopulation(['washGrantId', 'washer'], route);
  const [startProgram, { data: mutationData }] = useStartProgramMutation();
  const washGrantId = useAppState((state) => state.washGrantId);
  const location = useAppState((s) => s.locations);
  const [loading, setLoading] = useState(false);
  const [washStarted, setWashStarted] = useState(false);
  const toast = useToast();
  const washerType = findWasher(location, parseInt(route.params.washer));

  // Listen to changes to status
  useEffect(() => {
    if (mutationData?.startProgram?.status) {
      switch (mutationData.startProgram.status) {
        case Swash.StartProgramStatus.STARTED:
          setWashStarted(true);
          break;
        case Swash.StartProgramStatus.EXPIRED:
          toast.warning('Tilgangen din på vaskehallen har dessverre utløpt');
          break;
        case Swash.StartProgramStatus.USED:
          toast.warning('Det ser ut som om du allerede har benyttet denne tilgangen');
          break;
        default:
          break;
      }
    }
  }, [mutationData]);

  const onStartProgram = async () => {
    setLoading(true);
    const mutationInput = {
      washGrantId,
    };

    try {
      const { errors } = await startProgram({
        variables: mutationInput,
      });
      if (errors) {
        throw new Error(errors[0].message);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Allow screen to populate the state from query params
  if (!washGrantId || isLoadingQueryParams) return <ScreenLoader />;

  return (
    <Layout>
      {washStarted ? (
        <View>
          <H1>VASKEN ER I GANG</H1>
          <Button title="Fyll på mer" stretch style={tailwind('mt-8')} onPress={() => {}} />
        </View>
      ) : (
        <View style={tailwind('justify-between h-full')}>
          <View>
            <H1>VASKEN ER BETALT</H1>
            {washerType?.washer_type === WasherTypesEnum.CONVEYOR ? (
              <ListSteps>
                <H3 style={tailwind('m-0')}>Kjør frem til vaskeport</H3>
                <H3 style={tailwind('m-0')}>
                  Trykk {'"'}start vask{'"'}
                </H3>
                <H3 style={tailwind('m-0')}>Porten åpnes</H3>
                <H3 style={tailwind('m-0')}>Kjør frem til rødt lys</H3>
                <H3 style={tailwind('m-0')}>Vasken startes</H3>
              </ListSteps>
            ) : (
              <ListSteps>
                <H3 style={tailwind('m-0')}>Gjør dine valg på terminal</H3>
                <H3 style={tailwind('m-0')}>Start vask</H3>
              </ListSteps>
            )}
          </View>
          <Button
            title="Start vask"
            stretch
            style={tailwind('mt-8')}
            loading={loading}
            onPress={onStartProgram}
          />
        </View>
      )}
    </Layout>
  );
};

export default WashConfirmation;
