import React, { useContext, useEffect, useState } from 'react';
import { KeyboardTypeOptions, TextInput, View } from 'react-native';
import { useAppState } from '../state';
import { tailwind } from '../styles/tailwind';
import { dismissKeyboard } from '../utils/dismissKeyboard';
import { phoneNumberRegex, validatePhoneNumber } from '../utils/phoneValidation';
import Button from './Button';

interface InputContextProps {
  hasButton: boolean;
  setHasButton: (hasButton: boolean) => void;
  validPhoneNumber: boolean;
  setValidPhoneNumber: (value: boolean) => void;
  seperateFields: boolean;
}

const initialState = {
  hasButton: false,
  setHasButton: () => {},
  setValidPhoneNumber: () => {},
  validPhoneNumber: false,
  seperateFields: true,
};

const InputContext = React.createContext<InputContextProps>(initialState);

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  seperateFields?: boolean;
}

const Input = ({ children, seperateFields = false }: Props) => {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [hasButton, setHasButton] = useState(false);

  const seperateFieldsClass = seperateFields
    ? 'h-full justify-between flex-1'
    : 'flex-row items-center';

  useEffect(() => {
    setHasButton(Array.isArray(children) && children.length > 1);
  }, [children]);

  return (
    <InputContext.Provider
      value={{
        hasButton,
        setHasButton,
        validPhoneNumber,
        setValidPhoneNumber,
        seperateFields,
      }}
    >
      <View style={tailwind(`${seperateFieldsClass}`)}>{children}</View>
    </InputContext.Provider>
  );
};

interface InputFieldProps {
  onChangeText: (text: string) => void;
  placeholder: string;
  placeholderTextColor?: string;
  value: string;
  onSubmitEditing: () => void;
  keyboardType?: KeyboardTypeOptions;
}

const InputField = ({
  placeholder,
  placeholderTextColor = '#05248099',
  onChangeText,
  value,
  onSubmitEditing,
  keyboardType = 'phone-pad',
}: InputFieldProps) => {
  const { seperateFields, hasButton } = useContext(InputContext);
  const withButtonClass = hasButton ? 'flex-1 rounded-l-xl' : 'rounded-xl';
  const seperateFieldsClass = seperateFields ? 'rounded-xl' : 'flex-1 rounded-l-xl';

  return (
    <TextInput
      keyboardType={keyboardType}
      style={{
        ...tailwind(`h-16 bg-white font-montreal p-5 ${withButtonClass}  ${seperateFieldsClass}`),
        fontSize: 18,
        flexGrow: seperateFields ? 0 : 2,
      }}
      onChangeText={onChangeText}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      value={value}
      onSubmitEditing={onSubmitEditing}
    />
  );
};

interface PhoneInputProps {
  onSubmitEditing?: () => void;
  onChangeText?: (text: string) => void;
  value?: string;
  placeholder?: string;
  placeholderTextColor?: string;
  useGlobalPhonenumber?: boolean;
}

const PhoneInput = ({
  placeholder = 'Ditt telefonnummer',
  placeholderTextColor = '#05248099',
  value,
  onSubmitEditing,
  onChangeText,
}: PhoneInputProps) => {
  const { validPhoneNumber, setValidPhoneNumber } = useContext(InputContext);
  const phoneNumber = useAppState((state) => state.phoneNumber);
  const setPhoneNumber = useAppState((state) => state.setPhoneNumber);

  useEffect(() => {
    setValidPhoneNumber(!!validatePhoneNumber(phoneNumber));
  }, [phoneNumber, setValidPhoneNumber]);

  const updatePhoneNumber = (text: string) => {
    if (!phoneNumberRegex.test(text)) return;

    // Use specified onChangeText function if provided, if not set global phoneNumber
    if (onChangeText) {
      onChangeText(text);
    } else {
      setPhoneNumber(text);

      const validInput = validatePhoneNumber(text);
      if (validInput) {
        setPhoneNumber(validInput.toString());
      }
    }
  };

  const onSubmit = () => {
    if (!validPhoneNumber) return;

    if (onSubmitEditing) {
      onSubmitEditing();
    }
  };

  return (
    <InputField
      placeholder={placeholder}
      keyboardType="phone-pad"
      placeholderTextColor={placeholderTextColor}
      onChangeText={updatePhoneNumber}
      value={value ?? phoneNumber}
      onSubmitEditing={onSubmit}
    />
  );
};

interface InputButtonProps {
  title: string;
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
}

const InputButton = ({ title, disabled, loading, onPress }: InputButtonProps) => {
  const { seperateFields } = useContext(InputContext);
  const seperateFieldsClass = seperateFields ? '' : 'rounded-l-none flex-1';

  return (
    <Button
      title={title}
      style={tailwind(`${seperateFieldsClass}`)}
      stretch
      disabled={disabled}
      loading={loading}
      onPress={onPress}
    />
  );
};

const PhoneButton = ({ loading, onPress, title }: InputButtonProps) => {
  const { validPhoneNumber } = useContext(InputContext);

  const onPressHandler = () => {
    if (!validPhoneNumber) return;
    dismissKeyboard();

    onPress();
  };

  return (
    <InputButton
      title={title ?? 'Klar'}
      disabled={!validPhoneNumber}
      onPress={onPressHandler}
      loading={loading}
    />
  );
};

Input.Field = InputField;
Input.Button = InputButton;
Input.PhoneField = PhoneInput;
Input.PhoneButton = PhoneButton;

export default Input;
