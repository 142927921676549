import { SceneProgress } from '@react-navigation/stack/lib/typescript/src/types.d';
import React from 'react';
import { Animated, ViewStyle } from 'react-native';
import { opacityInterpolation } from '../../utils/opacityInterpolation';

interface Props {
  progress: SceneProgress;
  children: React.ReactNode;
  style?: ViewStyle;
}

const AnimatedHeader = ({ progress, children, style }: Props) => {
  const opacity = opacityInterpolation(progress);

  return <Animated.View style={{ ...style, opacity }}>{children}</Animated.View>;
};

export default AnimatedHeader;
