enum WashRoutes {
  WASHLANDING = 'washLanding',
  WASHLOCATION = 'washLocation',
  WASHTYPE = 'washType',
  WASHPROGRAM = 'washProgram',
  WASHSUMMARY = 'washSummary',
  WASHCONFIRMATION = 'washConfirmed',
}

export default WashRoutes;
