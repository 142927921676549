import React, { useState } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { tailwind } from '../../../styles/tailwind';
import webLayoutConstraints from '../../../styles/webLayoutConstraints';
import MenuModal from '../Modal';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

interface HeaderContextProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: HeaderContextProps = {
  modalVisible: false,
  setModalVisible: () => {},
};

export const HeaderContext = React.createContext<HeaderContextProps>(initialState);

const Header = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const insets = useSafeAreaInsets();

  return (
    <View style={{ ...tailwind('bg-swash-saphire w-full'), paddingTop: insets.top }}>
      <View
        style={{
          ...tailwind('flex-row my-4 justify-between bg-swash-saphire w-full'),
          ...webLayoutConstraints,
        }}
      >
        <HeaderContext.Provider value={{ modalVisible, setModalVisible }}>
          <HeaderLeft />
          <HeaderRight />
          <MenuModal />
        </HeaderContext.Provider>
      </View>
    </View>
  );
};

export default Header;
