/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import WashRoutes from '../../domain/wash/WashRoutes';
import { RootStackParamList } from './navigationTypes';
import RootRoutes from './RootRoutes';

const linking: LinkingOptions<RootStackParamList> = {
  // In the hosting service you have to rewrite '/*' to '/index.html' for the internal linking to be handled by the client application.
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      [RootRoutes.ROOT]: {
        path: '',
        screens: {
          [WashRoutes.WASHLANDING]: {
            path: '',
          },
          [WashRoutes.WASHLOCATION]: {
            path: ':id?/',
          },
          [WashRoutes.WASHTYPE]: {
            path: ':id/wash',
          },
          [WashRoutes.WASHPROGRAM]: {
            path: ':id/program',
          },
          [WashRoutes.WASHSUMMARY]: {
            path: ':id/summary',
          },
          [WashRoutes.WASHCONFIRMATION]: ':id/confirmation',
        },
      },
      [RootRoutes.LOGIN]: {
        path: 'login',
      },
      [RootRoutes.ADDCAR]: {
        path: 'add-car',
      },
      [RootRoutes.ADDCARCONFIRMATION]: {
        path: 'add-car/confirmation',
      },
      [RootRoutes.NOTFOUND]: {
        path: '*',
      },
      [RootRoutes.SIGNOUT]: 'sign-out',
    },
  },
};

export default linking;
