import React from 'react';
import { Image, ImageSourcePropType } from 'react-native';
import { tailwind } from '../styles/tailwind';
// Need to import images like this according to expo. Could also use the expo 'useAsset'-hook.
const bgDark = require('../assets/images/bg-image-dark.png');

interface Props {
  source?: ImageSourcePropType;
}

const AssetBackgroundImage = ({ source }: Props) => {
  return (
    <Image
      source={source ?? bgDark}
      style={{
        ...tailwind('absolute bottom-0 w-full h-full opacity-20'),
        maxWidth: 764,
        width: '100%',
        maxHeight: 350,
      }}
    />
  );
};

export default AssetBackgroundImage;
