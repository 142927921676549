import React from 'react';
import { View } from 'react-native';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import AssetBackgroundImage from '../../../common/components/AssetBackgroundImage';
import Button from '../../../common/components/Button';
import Divider from '../../../common/components/Divider';
import Input from '../../../common/components/Input';
import Layout from '../../../common/components/Layout';
import { H1 } from '../../../common/components/Typography';
import { useUserState } from '../../../common/state';
import { UserInfo } from '../../car/types';
import { appConfig } from '../../../common/config/appConfig';
import { useToast } from '../../../common/hooks/useToast';
import RootRoutes from '../../../common/navigation/RootRoutes';
import { tailwind } from '../../../common/styles/tailwind';
// eslint-disable-next-line unused-imports/no-unused-vars
const vippsLogo = require('../../../common/assets/logos/vipps-rgb-orange-pos.svg');

WebBrowser.maybeCompleteAuthSession();
const useProxy = false;
const redirectUri = AuthSession.makeRedirectUri({ useProxy });

interface Props {
  navigation: any;
}

const Login = ({ navigation }: Props) => {
  const setIdToken = useUserState((state) => state.setIdToken);
  const setUserInfo = useUserState((state) => state.setUserInfo);
  const toast = useToast();

  const [, , promptAsync] = AuthSession.useAuthRequest(
    {
      redirectUri,
      clientId: appConfig.env.AUTH0_CLIENT_ID as string,
      responseType: 'id_token',
      scopes: ['openid', 'profile', 'email'],
      extraParams: {
        nonce: 'nonce',
      },
    },
    { authorizationEndpoint: appConfig.env.AUTH0_DOMAIN + '/authorize' }
  );

  const onLoginWithAuth = async () => {
    const response: AuthSession.AuthSessionResult = await promptAsync({ useProxy });

    if (response.type === 'success') {
      const jwtToken = response.params.id_token;
      const decoded = jwtDecode<JwtPayload>(jwtToken) as UserInfo;

      setIdToken(jwtToken);
      setUserInfo(decoded);
      navigation.replace(RootRoutes.ROOT);
      toast.success(`User name: ${decoded.given_name}`, `User email: ${decoded.email}`, {
        autoHide: false,
      });
    }
  };

  const onLogout = async () => {
    const res = await WebBrowser.openAuthSessionAsync(
      `${appConfig.env.AUTH0_DOMAIN}/v2/logout?client_id=${appConfig.env.AUTH0_CLIENT_ID}&returnTo=${redirectUri}`,
      redirectUri
    );

    if (res.type === 'success') {
      navigation.replace(RootRoutes.LOGIN);
      setIdToken('');
      setUserInfo(null);
    }
  };

  const onLogin = () => {
    navigation.replace(RootRoutes.ROOT, {
      id: '1',
    });
  };

  return (
    <View style={tailwind('h-full bg-swash-saphire')}>
      <AssetBackgroundImage />
      <Layout>
        <H1>LOGG INN</H1>
        <Button title="Logg inn med Vipps" stretch onPress={onLoginWithAuth} />
        <Button title="Logg out" stretch style={{ marginTop: 10 }} onPress={onLogout} />
        <Divider title="eller" />
        <View style={tailwind('flex-1')}>
          <Input>
            <Input.PhoneField onSubmitEditing={onLogin} />
            <Input.PhoneButton title="Logg inn" onPress={onLogin} />
          </Input>
        </View>
      </Layout>
    </View>
  );
};

export default Login;
