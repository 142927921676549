import { useCallback, useEffect, useState } from 'react';
import { useGetLocationsLazyQuery } from '../graphql/generated/apollo';
import { Swash } from '../graphql/types';
import { useAppState, useUserState } from '.';

type queryParamType = string | null;
export type possibleQueryParams =
  | 'id'
  | 'washer'
  | 'program'
  | 'price'
  | 'washGrantId'
  | 'error'
  | 'car';

// eslint-disable-next-line unused-imports/no-unused-vars
export const useQueryPopulation = (queryParams: possibleQueryParams[], route: any) => {
  const [isLoadingQueryParams, setIsLoadingQueryParams] = useState(false);
  const [getLocations] = useGetLocationsLazyQuery();
  const locations = useAppState((state) => state.locations);
  const setLocations = useAppState((state) => state.setLocations);
  const setLocation = useAppState((state) => state.setLocation);
  const getLocationById = useAppState((state) => state.getLocationById);
  const setWasher = useAppState((state) => state.setWasher);
  const getWasherById = useAppState((state) => state.getWasherById);
  const getWasherProgramById = useAppState((state) => state.getWasherProgramById);
  const setWasherProgram = useAppState((state) => state.setWasherProgram);
  const setProgramPrice = useAppState((state) => state.setProgramPrice);
  const setWashGrantId = useAppState((state) => state.setWashGrantId);
  const getCarById = useUserState((state) => state.getCarById);
  const setSelectedCar = useUserState((state) => state.setSelectedCar);

  const fetchLocations = useCallback(async () => {
    if (locations) return;
    setIsLoadingQueryParams(true);
    const { data } = await getLocations();
    if (data?.locations) setLocations(data.locations);
    setIsLoadingQueryParams(false);
  }, [getLocations, setLocations, locations]);

  useEffect(() => {
    if (!locations) {
      fetchLocations();
    }
  }, [fetchLocations, locations]);

  const populateState = async () => {
    setIsLoadingQueryParams(true);

    let location: Swash.Location | null = null;
    let washer: Swash.Washer | null = null;

    if (queryParams.includes('id')) {
      const locationId: string | null = route.params?.id;
      if (locationId) {
        location = getLocationById(parseInt(locationId));
        setLocation(location);
      }
    }

    if (location && queryParams.includes('washer')) {
      const washerId = route.params?.washer ?? null;
      if (washerId) {
        washer = getWasherById(parseInt(washerId));
        setWasher(washer);
      }
    }

    // TODO: Should we check if washer is self_service or not, cause if so we don't want programId, but amount (?)
    if (location && queryParams.includes('program')) {
      const programId = route.params?.program ?? null;
      if (programId) {
        const program = getWasherProgramById(parseInt(programId));
        setWasherProgram(program);
        // Don't want to override the programPrice if it's being set dynamically by self_service-choice
        if (washer?.washer_type === 'conveyor' && program) {
          setProgramPrice(program.price_details?.price ?? 0);
        }
      }
    }

    if (location && queryParams.includes('price')) {
      const price = route.params?.price ?? null;
      if (price) {
        setProgramPrice(parseInt(price));
      }
    }

    if (queryParams.includes('washGrantId')) {
      const washGrantId: queryParamType = route.params?.washGrantId ?? null;
      setWashGrantId(washGrantId);
    }

    if (washer && queryParams.includes('error')) {
      const error: queryParamType = route.params?.type ?? '';
      // TODO: Set error to state
      // eslint-disable-next-line no-console
      console.log(error);
    }

    if (queryParams.includes('car')) {
      const carId: queryParamType = route.params?.car ?? '';
      if (carId) {
        const car = getCarById(carId);
        setSelectedCar(car);
      }
    }

    setIsLoadingQueryParams(false);
  };

  // TODO: Include all query params in the effect. Right now it causes infinite loop
  useEffect(() => {
    if (!locations) return;
    populateState();
  }, [route.params, locations]);

  return { isLoadingQueryParams };
};
