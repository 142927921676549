import { ProgramFragment } from '../graphql/generated/apollo';

export const priceToString = (price: number) => price / 100;

export const stringToPrice = (price: string) => {
  const priceNumber = parseInt(price, 10);
  if (Number.isNaN(priceNumber)) {
    return null;
  }
  return priceNumber * 100;
};

export const getProgramPrice = (program: ProgramFragment) => {
  return program.price_details?.price || 0;
};
