import React from 'react';
import Layout from './Layout';
import LoadingIndicator from './LoadingIndicator';

const ScreenLoader = () => {
  return (
    <Layout center>
      <LoadingIndicator />
    </Layout>
  );
};

export default ScreenLoader;
