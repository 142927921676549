import React from 'react';
import { TouchableOpacity, View, Image, TransformsStyle } from 'react-native';
import { tailwind } from '../../../common/styles/tailwind';
import { WasherTypesEnum } from '../../../common/graphql/generated/apollo';
import { Swash } from '../../../common/graphql/types';
import { priceToString } from '../../../common/utils/price';
import { H2, H3 } from '../../../common/components/Typography';
import washerMinPrice from '../utils/programsMinPrice';

const assetLight = require('../../../common/assets/images/card-asset-light.svg');
const assetDark = require('../../../common/assets/images/card-asset-dark.svg');

interface Props {
  washer: Swash.Washer;
  onPress: () => void;
}

const WashTypeCard = ({ washer, onPress }: Props) => {
  let bgColorClass: string;
  let headerTextClass: string;
  let bodyTextClass: string;
  let assetImage: any;
  let transform: TransformsStyle['transform'];
  let displayName: string;

  switch (washer.washer_type) {
    case WasherTypesEnum.CONVEYOR:
      bgColorClass = 'bg-swash-soap';
      headerTextClass = 'text-swash-saphire';
      bodyTextClass = 'text-swash-saphire';
      assetImage = assetDark;
      transform = [{ translateX: 15 }, { scale: 1.15 }, { translateY: 5 }];
      displayName = washer.name;
      break;
    case WasherTypesEnum.SELF_SERVICE:
      bgColorClass = 'bg-swash-water';
      headerTextClass = 'text-swash-soap';
      bodyTextClass = 'text-swash-snow';
      assetImage = assetLight;
      transform = [{ translateX: 10 }, { scale: 1.0 }];
      displayName = washer.name;
      break;
    default:
      bgColorClass = 'bg-swash-water';
      headerTextClass = 'text-swash-soap';
      bodyTextClass = 'text-swash-snow';
      assetImage = assetLight;
      transform = [{ translateX: 10 }, { scale: 1.15 }];
      displayName = '...';
      break;
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={tailwind(`w-full px-6 py-6 rounded-xl ${bgColorClass} overflow-hidden`)}>
        <View
          style={{
            ...tailwind('absolute right-0 bottom-0 h-full w-1/2 z-0 max-w-xs'),
            transform,
          }}
        >
          <Image
            source={assetImage}
            resizeMode="cover"
            style={tailwind('w-full h-full bg-transparent opacity-30')}
          />
        </View>
        <View style={tailwind('z-10')}>
          <H2 style={tailwind(`m-0 ${headerTextClass} uppercase`)}>{displayName}</H2>
          <H3 style={tailwind(`m-0 ${bodyTextClass}`)}>
            fra kr {priceToString(washerMinPrice(washer)).toString()},-
          </H3>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default WashTypeCard;
