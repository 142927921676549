import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { SceneProgress } from '@react-navigation/stack/lib/typescript/src/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Routes, WizardStep, WizardType } from '../../types';
import webLayoutConstraints from '../../../styles/webLayoutConstraints';
import AnimatedHeader from './AnimatedHeader';
import Breadcrumbs from './Breadcrumbs';

interface Props {
  wizard: WizardType;
  progress: SceneProgress;
}

const WizardHeader = ({ wizard, progress }: Props) => {
  const [currRoute, setCurrRoute] = useState<WizardStep>();
  const route = useRoute();
  const navigation = useNavigation<NativeStackNavigationProp<any, Routes>>();

  const findWizardStep = useCallback(
    (wizardRoute: Routes) => {
      if (!wizard) return null;
      return wizard.steps.find((step) => step.route === wizardRoute) ?? null;
    },
    [wizard]
  );

  useEffect(() => {
    const newCurrentRoute = findWizardStep(route.name as Routes);
    if (newCurrentRoute) {
      setCurrRoute(newCurrentRoute);
    }
  }, [route.name, findWizardStep, wizard]);

  const isSameWizardStep = (routeName: Routes): boolean => {
    return currRoute?.route === routeName || false;
  };

  const haveAllRequiredParams = (step: WizardStep) => {
    const requiredParamsValues = Object.values(step.requiredParams ?? {});
    const nullParams = requiredParamsValues.filter((param) => param === null);
    return nullParams.length === 0;
  };

  return wizard ? (
    <AnimatedHeader progress={progress} style={webLayoutConstraints}>
      <Breadcrumbs>
        {wizard.steps.map((wizardStep, index) => (
          <Breadcrumbs.Crumb
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            disabled={!haveAllRequiredParams(wizardStep) || isSameWizardStep(wizardStep.route)}
            title={wizardStep.title}
            isActive={currRoute?.route === wizardStep.route}
            onPress={() =>
              navigation.navigate(wizardStep.route, {
                ...wizardStep.requiredParams,
              })
            }
          />
        ))}
      </Breadcrumbs>
    </AnimatedHeader>
  ) : null;
};

export default WizardHeader;
