import React from 'react';
import { View } from 'react-native';
import Button from '../../../common/components/Button';
import { BodyText } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';
import { Car } from '../types';
import CarCard from './CarCard';

interface Props {
  car: Car;
  confirmAddCar: (car: Car) => void;
  setCar: (car: Car | null) => void;
}

const CarConfirm = ({ car, confirmAddCar, setCar }: Props) => {
  return (
    <View style={tailwind('h-full flex-1')}>
      <CarCard car={car} />
      <BodyText>
        Vi fant denne bilen i systemene våre. Se over at alt stemmer før du går videre
      </BodyText>
      <View style={tailwind('flex-1 items-end flex-row mt-4')}>
        <Button
          style={tailwind('flex-1 mr-2')}
          title="Det ser riktig ut"
          onPress={() => confirmAddCar(car)}
          stretch
        />
        <Button
          style={tailwind('flex-1 ml-2')}
          title="Endre info"
          onPress={() => setCar(null)}
          stretch
          backgroundColor="transparent"
          border="border-solid"
        />
      </View>
    </View>
  );
};

export default CarConfirm;
