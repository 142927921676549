import { SceneProgress } from '@react-navigation/stack/lib/typescript/src/types.d';
import React, { useMemo } from 'react';
import { useAppState } from '../../../../common/state';
import WizardHeader from '../../../../common/navigation/components/header/WizardHeader';
import { washWizard } from '../washWizard';

interface Props {
  progress: SceneProgress;
}

const WashWizardHeader = ({ progress }: Props) => {
  const location = useAppState((state) => state.location);
  const washer = useAppState((state) => state.washer);
  const program = useAppState((state) => state.washerProgram);
  const programPrice = useAppState((state) => state.programPrice);

  const wizard = useMemo(
    () =>
      washWizard(
        location?.id ?? null,
        washer?.id ?? null,
        program?.id ?? null,
        programPrice ?? null
      ),
    [location, washer, program, programPrice]
  );

  return <WizardHeader wizard={wizard} progress={progress} />;
};

export default WashWizardHeader;
