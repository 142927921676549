/* eslint-disable no-console */
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { MessageType } from '../components/Messages';
import { IAppState, IMessageState, IUserState } from './types';

// TODO: Split the state when it gets too big
export const useAppState = create<IAppState>((set, get) => ({
  phoneNumber: '',
  setPhoneNumber: (phoneNumber) => set((state) => ({ ...state, phoneNumber })),

  paymentMethod: 'Vipps',
  setPaymentMethod: (paymentMethod) => set((state) => ({ ...state, paymentMethod })),

  washGrantId: null,
  setWashGrantId: (washGrantId) => set((state) => ({ ...state, washGrantId })),

  locations: null,
  setLocations: (locations) => set((state) => ({ ...state, locations })),

  location: null,
  setLocation: (location) => set((state) => ({ ...state, location })),
  getLocationById: (id) => {
    const locations = get().locations;
    if (!locations) return null;

    const location = locations.find((loc) => loc.id === id) ?? null;
    if (!location) {
      console.error(`Location with id ${id} not found`);
    }
    return location;
  },

  washer: null,
  setWasher: (washer) => set((state) => ({ ...state, washer })),
  getWasherById: (id) => {
    const location = get().location;
    if (!location) return null;

    const washer = location.washers.find((w) => w.id === id) ?? null;
    if (!washer) {
      console.error(`Could not find washer with id ${id}`);
    }
    return washer;
  },

  washerProgram: null,
  setWasherProgram: (washerProgram) => set((state) => ({ ...state, washerProgram })),
  getWasherProgramById: (id) => {
    const washer = get().washer;
    if (!washer) return null;

    const program = washer.programs.find((p) => p.id === id) ?? null;
    if (!program) {
      console.error(`Could not find program with id ${id}`);
    }
    return program;
  },

  programPrice: null,
  setProgramPrice: (selfServiceAmount) =>
    set((state) => ({ ...state, programPrice: selfServiceAmount })),
}));

export const useUserState = create<IUserState>(
  persist(
    (set, get) => ({
      cars: [],
      idToken: '',
      userInfo: null,
      setIdToken: (idToken) => {
        set((state) => ({ ...state, idToken }));
      },
      setUserInfo: (userInfo) => {
        set((state) => ({ ...state, userInfo }));
      },
      addCar: (car) => {
        const cars = get().cars;
        set((state) => ({ ...state, cars: [...cars, car] }));
      },
      getCarById: (id) => {
        const cars = get().cars;
        return cars.find((car) => car.kjennemerke === id) ?? null;
      },
      selectedCar: null,
      setSelectedCar: (car) => set((state) => ({ ...state, selectedCar: car })),
    }),
    {
      name: 'user',
    }
  )
);

export const useMessageState = create<IMessageState>((set) => ({
  message: null,
  setInfoMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.INFO, message, description, options },
    })),
  setSuccessMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.SUCCESS, message, description, options },
    })),
  setWarningMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.WARNING, message, description, options },
    })),
  setDangerMessage: (message, description, options) =>
    set((state) => ({
      ...state,
      message: { type: MessageType.DANGER, message, description, options },
    })),
  clearMessage: () => set((state) => ({ ...state, message: null })),
}));
