import React from 'react';
import { View } from 'react-native';
import Button from '../../../common/components/Button';
import Layout from '../../../common/components/Layout';
import ScreenLoader from '../../../common/components/ScreenLoader';
import { BodyText, H1 } from '../../../common/components/Typography';
import RootRoutes from '../../../common/navigation/RootRoutes';
import { useUserState } from '../../../common/state';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import { tailwind } from '../../../common/styles/tailwind';
import CarCard from '../components/CarCard';

const AddedCarConfirmation = ({ navigation, route }: any) => {
  const { isLoadingQueryParams } = useQueryPopulation(['car'], route);
  const selectedCar = useUserState((state) => state.selectedCar);
  const setSelectedCar = useUserState((state) => state.setSelectedCar);

  const onGoToDashboard = () => {
    setSelectedCar(null);
    navigation.navigate(RootRoutes.ROOT);
  };

  if (!selectedCar || isLoadingQueryParams) return <ScreenLoader />;

  return (
    <Layout>
      <View style={tailwind('h-full')}>
        <H1 style={tailwind('mb-4')}>BILEN BLE LAGT TIL</H1>
        <CarCard car={selectedCar} />
        <BodyText>Du finner en oversikt over alle dine biler i dashboardet</BodyText>
        <View style={tailwind('flex-1 justify-end')}>
          <Button title="Gå til dashboard" onPress={onGoToDashboard} stretch />
        </View>
      </View>
    </Layout>
  );
};

export default AddedCarConfirmation;
