import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAppState } from '../../../common/state';
import { useQueryPopulation } from '../../../common/state/useQueryPopulation';
import WashRoutes from '../WashRoutes';
import Button from '../../../common/components/Button';
import { tailwind } from '../../../common/styles/tailwind';
import Layout from '../../../common/components/Layout';
import ScreenLoader from '../../../common/components/ScreenLoader';
import { H1, H3, BodyText } from '../../../common/components/Typography';
import ScreenError from '../../../common/components/ScreenError';

const WashLocation = ({
  navigation,
  route,
}: NativeStackScreenProps<any, WashRoutes.WASHLOCATION>) => {
  const { isLoadingQueryParams } = useQueryPopulation(['id'], route);
  const locations = useAppState((state) => state.locations);
  const location = useAppState((state) => state.location);

  if (isLoadingQueryParams) return <ScreenLoader />;
  if (!locations || !location) {
    return <ScreenError />;
  }

  return (
    <Layout>
      <View style={tailwind('justify-between h-full')}>
        <View>
          <H1>VASK BILEN PÅ 1-2-3</H1>
          <H3>{location.name}</H3>
          <BodyText>Velkommen til vaskehallen vår. For å komme igang, klikk knappen under</BodyText>
        </View>
        <Button
          title="Kom i gang"
          stretch
          onPress={() =>
            location?.id &&
            navigation.push(WashRoutes.WASHTYPE, {
              id: location.id,
            })
          }
        />
      </View>
    </Layout>
  );
};

export default WashLocation;
