import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Card from '../../../common/components/Card';
import { BodyText, BodySmall } from '../../../common/components/Typography';
import { tailwind } from '../../../common/styles/tailwind';
import { priceToString } from '../../../common/utils/price';
import RatingIcons from '../../../common/components/RatingIcon';

interface Props {
  programId?: number;
  name: string;
  description: string;
  price: number;
  rating: number;
  firstElement?: boolean;
  onPress: (amount: number, programId?: number) => void;
  offerPrice?: number | null;
}

const WashCard = ({
  name,
  description,
  price,
  firstElement,
  rating,
  onPress,
  programId,
  offerPrice = null,
}: Props) => {
  const spaceClass = firstElement ? '' : 'mt-4';

  return (
    <TouchableOpacity
      style={tailwind(`${spaceClass}`)}
      onPress={() => onPress(offerPrice || price, programId)}
    >
      {offerPrice ? (
        <BodyText
          style={tailwind(
            'font-montreal-bold absolute -top-2 -right-2 bg-swash-soap text-swash-saphire px-3 py-0.5 rounded z-20 uppercase text-xxs'
          )}
        >
          tilbud
        </BodyText>
      ) : null}
      <Card>
        <Card.Icon>
          <RatingIcons rating={rating} />
        </Card.Icon>
        <Card.Content>
          <View style={tailwind('flex flex-row justify-between items-center w-full')}>
            <View style={tailwind('flex-1')}>
              <BodyText>{name}</BodyText>
              <BodySmall>{description}</BodySmall>
            </View>
            {offerPrice ? (
              <View>
                <BodyText style={tailwind('ml-4 font-montreal-bold')}>
                  {priceToString(offerPrice)},-
                </BodyText>
                <BodyText style={tailwind('ml-4 text-sm text-right line-through')}>
                  {priceToString(price)},-
                </BodyText>
              </View>
            ) : (
              <BodyText style={tailwind('ml-4')}>{priceToString(price)},-</BodyText>
            )}
          </View>
        </Card.Content>
      </Card>
    </TouchableOpacity>
  );
};

export default WashCard;
