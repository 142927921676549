import React from 'react';
import { SafeAreaView, TouchableWithoutFeedback, View } from 'react-native';
import { tailwind } from '../styles/tailwind';
import webLayoutConstraints from '../styles/webLayoutConstraints';
import { dismissKeyboard } from '../utils/dismissKeyboard';

interface Props {
  center?: boolean;
  fullHeight?: boolean;
  withPadding?: boolean;
  children: React.ReactNode;
}

const Layout = ({ children, center, fullHeight = true, withPadding = true }: Props) => {
  const centerClass = center ? 'justify-center items-center' : '';
  const fullHeightClass = fullHeight ? 'h-full flex-1' : '';
  const paddingClass = withPadding ? 'px-4 py-6' : '';

  return (
    <SafeAreaView style={tailwind('flex-1')}>
      <TouchableWithoutFeedback onPress={dismissKeyboard} accessible={false}>
        <View
          style={{
            ...tailwind(`bg-transparent ${paddingClass} ${centerClass} ${fullHeightClass}`),
            ...webLayoutConstraints,
          }}
        >
          {children}
        </View>
      </TouchableWithoutFeedback>
    </SafeAreaView>
  );
};

export default Layout;
