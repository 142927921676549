enum RootRoutes {
  ROOT = 'root',
  LOGIN = 'login',
  ADDCAR = 'addcar',
  ADDCARCONFIRMATION = 'addCarConfirmation',
  MYCARS = 'myCars',
  WASHHISTORY = 'washHistory',
  SIGNOUT = 'signOut',
  NOTFOUND = 'notFound',
}

export default RootRoutes;
