import React from 'react';
import { ScrollView, ViewStyle } from 'react-native';
import { tailwind } from '../styles/tailwind';

interface Props {
  children: React.ReactNode;
  style?: ViewStyle;
}

const ScrollableContent = ({ children, style }: Props) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false} style={{ ...tailwind('px-4 py-6'), ...style }}>
      {children}
    </ScrollView>
  );
};

export default ScrollableContent;
