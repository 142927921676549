import WashRoutes from '../WashRoutes';
import { WizardType } from '../../../common/navigation/types';

// TODO: Refactor this to let requiredParams be a object of booleans so that this object doesn't need state to function.
export const washWizard = (
  locationId: number | null,
  washerId: number | null,
  programId: number | null,
  price: number | null
): WizardType => ({
  steps: [
    {
      title: 'Vasketype',
      route: WashRoutes.WASHTYPE,
      requiredParams: {
        id: locationId,
      },
    },
    {
      title: 'Program',
      route: WashRoutes.WASHPROGRAM,
      requiredParams: {
        id: locationId,
        washer: washerId,
      },
    },
    {
      title: 'Betaling',
      route: WashRoutes.WASHSUMMARY,
      requiredParams: {
        id: locationId,
        washer: washerId,
        program: programId,
        price,
      },
    },
  ],
});
