import { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import tailwind from 'tailwind-rn';
import Input from '../../../common/components/Input';
import { BodySmall } from '../../../common/components/Typography';

export default function PromoCodeInput({
  promoCode,
  setPromoCode,
}: {
  promoCode: string;
  setPromoCode: (v: string) => void;
}) {
  const [showPromoCodeField, setShowPromoCodeField] = useState(false);

  const togglePromoCode = () => {
    if (showPromoCodeField) setPromoCode('');
    setShowPromoCodeField(!showPromoCodeField);
  };
  return (
    <>
      <View style={tailwind('w-full flex')}>
        <TouchableOpacity style={tailwind('w-full text-center ')} onPress={togglePromoCode}>
          <BodySmall style={tailwind('underline mb-4')}>
            {showPromoCodeField ? 'Fjern promokode' : 'Har du en promokode?'}
          </BodySmall>
        </TouchableOpacity>
      </View>
      {showPromoCodeField && (
        <View style={tailwind('mb-4')}>
          <Input.Field
            value={promoCode}
            placeholder="Skriv inn promokoden her"
            onChangeText={setPromoCode}
            onSubmitEditing={() => {}}
          />
        </View>
      )}
    </>
  );
}
