import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Button from '../components/Button';
import Layout from '../components/Layout';
import { H3 } from '../components/Typography';

import { RootStackScreenProps } from '../navigation/navigationTypes';
import RootRoutes from '../navigation/RootRoutes';

export default function NotFoundScreen({ navigation }: RootStackScreenProps<RootRoutes.NOTFOUND>) {
  return (
    <Layout>
      <View>
        <H3>This screen doesnt exist.</H3>
        <TouchableOpacity onPress={() => navigation.replace(RootRoutes.ROOT)}>
          <Button title="Go to home screen!" onPress={() => navigation.replace(RootRoutes.ROOT)} />
        </TouchableOpacity>
      </View>
    </Layout>
  );
}
